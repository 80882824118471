import { FC, useCallback, useMemo, useState } from "react";

import moment from "moment";
import { useSession } from "next-auth/react";
import { Trans, useTranslation } from "next-i18next";
import { ApplicationStatusUpdaterParams } from "typings/application-status";

import { ContentPaste as ContentPasteIcon } from "@mui/icons-material";
import { Button, MenuItem, SelectChangeEvent } from "@mui/material";

import { InfoBox, Modal, ModalProps, SelectWithModal } from "@work4Labs/design-system";

import { ApplicationApi, ApplicationStatusReason, InterviewApi } from "@api";
import {
  COLOR_PALETTE,
  QUERY_KEYS,
  STATUSES_REASONS_WITH_SOURCE,
  STATUSES_WITH_OPTIONAL_SMS,
  STATUSES_WITH_REASON,
} from "@constants";
import { loadTranslations } from "@lib";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Application,
  ApplicationListItem,
  ApplicationStatusReasonWhyForm,
  ApplicationStatusUpdateParams,
  AtsIntegration,
  ORGANIZATIONS_FEATURES,
  Status,
} from "@typings";

import {
  updateApplicationsCache,
  useApplicationStatusReason,
  useCurrentUserOrganization,
  useFetchInterviewsConfigurationByCampaignID,
  useFetchTriggersByCampaignID,
  useOrganizationFeatures,
} from "@hooks/queries";

import { ApplicationInterviewModal } from "../../application/application-interview-modals";
import { StatusChip } from "../status-chip";
import {
  RenderATSIntegration,
  RenderTriggers,
  SendSMSModalForm,
  StatusSelectorLabel,
  UpdateReasonWhyForm,
} from "./components";

/**
 * Handlers for application status.
 */
const useStatuses = (application?: Application | ApplicationListItem) => {
  // List available statuses for the current application.
  const statusesQuery = useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS_STATUSES],
    queryFn: () => ApplicationApi.listStatuses(),
    refetchOnWindowFocus: true,
    gcTime: Infinity,
  });

  // MReturn an API status object from a given label, if it exists.
  const findStatusFromLabel = useCallback(
    (label: string) => statusesQuery.data?.find((status) => status.label === label) ?? null,
    [statusesQuery.data],
  );

  // Retrieve the current status of the application (most recent one).
  const applicationStatus = useMemo(() => {
    if (application && "statuses" in application) {
      return application?.statuses.reduce(
        (acc, value) => (moment(value.created_at).isAfter(acc.created_at) ? value : acc),
        application.statuses[0],
      );
    }
    return undefined;
  }, [application]);

  return { statusesQuery, findStatusFromLabel, applicationStatus };
};

/**
 * Update the application status (and clear the cache).
 * You can pass extra params with the `updateParams` to specify the reason why and send a sms
 */
export const updateApplicationStatus = async ({
  applicationID,
  newStatus,
  queryClient,
  updateParams,
  isBulkAction,
}: ApplicationStatusUpdaterParams) => {
  if (newStatus === null) {
    return;
  }

  const statusRes = await ApplicationApi.updateApplicationStatus({
    application_id: applicationID,
    body: { id: newStatus.id, is_bulk_action: isBulkAction === true, send_sms: updateParams.sendSMS },
  });

  // Update reason why if available.
  if (updateParams.reasonWhy) {
    await ApplicationApi.createApplicationStatusReason({
      application_id: applicationID,
      status_id: statusRes.id,
      body: {
        source: (STATUSES_REASONS_WITH_SOURCE.includes(newStatus.label) && updateParams.reasonWhy.source) || "",
        reason: updateParams.reasonWhy.reason as ApplicationStatusReason["reason"],
        comment: updateParams.reasonWhy.comment ?? "",
      },
    });
  }

  updateApplicationsCache(queryClient, {
    id: applicationID,
    status: newStatus.label,
    status_reason: updateParams?.reasonWhy?.reason || "",
  });
};

const buttonStyle = {
  fontSize: "1rem",
  fontWeight: "600",
  textTransform: "none",
  borderRadius: "8px",
};

interface SelectApplicationStatusProps {
  /**
   * The ID of the campaign the application belongs to.
   */
  campaignId: string;
  /**
   * The applications for which the status is being updated.
   */
  applications: (Application | ApplicationListItem)[];
  /**
   * Custom value for the input label.
   */
  customLabel?: string;

  /**
   * Callback for when the user submits any of the substatuses forms.
   * Mainly used for when the SelectApplicationStatus component is used inside a modal
   */
  onStatusConfirm?: (newStatus: Status, params: ApplicationStatusUpdateParams) => void;
  /**
   * Allow the user to choose if they want to send an SMS or not, if available.
   */
  sendSMS?: boolean;
  /**
   * Callback for when the status is changed.
   *
   * The second argument contains extra parameters for updates.
   */
  onChange: (newStatus: Status | null, params: ApplicationStatusUpdateParams) => void;
  dropDownScaleWidth?: number;
}

/**
 * A component to select the new status for a list of applications. It contains logic to display diverse pop-up depending on the
 * status selected.
 * @returns
 */
export const SelectApplicationStatus: FC<SelectApplicationStatusProps> = ({
  campaignId,
  applications,
  customLabel,
  onStatusConfirm,
  dropDownScaleWidth,
  sendSMS: withSendSMS,
  onChange: onChangeProp,
}) => {
  // Instantiate dependencies.
  const { t } = useTranslation(["application-status", "status-selector-field"]);
  loadTranslations("application-status");
  loadTranslations("status-selector-field");

  // this var is here to differentiate between bulk update and single application.
  // some things are specifically done for the single update, like showing the current status or skipping the interview
  // but it's not possible if we have multiple applications to update.
  const singleApplicationInList = applications.length == 1 ? applications[0] : undefined;

  const { data: triggers } = useFetchTriggersByCampaignID({ campaign_id: campaignId });

  // TODO FIX const { data: atsIntegration } = useFetchAtsIntegrationByCampaignID({ campaign_id: campaignId });
  const queryClient = useQueryClient();
  const atsIntegration = queryClient.getQueryData<AtsIntegration>([QUERY_KEYS.ATS_INTEGRATIONS, campaignId]);

  const { data: session } = useSession();
  const currentOrganizationQuery = useCurrentUserOrganization(session?.user?.id);
  const { hasFeature: organizationHasFeature } = useOrganizationFeatures(currentOrganizationQuery.data?.group_id ?? "");

  // Form data.
  const [reasonWhy, setReasonWhy] = useState<ApplicationStatusReasonWhyForm>();
  const [reasonWhyFormValid, setReasonWhyFormValid] = useState<boolean>(false);
  const [sendSMS, setSendSMS] = useState<boolean>();

  // Here are the hooks we are going to use to interact with the data being updated.
  const { statusesQuery, findStatusFromLabel, applicationStatus } = useStatuses(singleApplicationInList);
  const applicationStatusReason = useApplicationStatusReason(applicationStatus, singleApplicationInList);

  // Render the status label.
  const statusRenderValue = useCallback(
    (label: string | undefined) =>
      label ? (
        <StatusChip
          status={label}
          // Only show reason while status has not been edited (otherwise it leads to incoherent states).
          statusReason={applicationStatus?.label === label ? applicationStatusReason?.data?.reason : undefined}
        />
      ) : (
        <></>
      ),
    [applicationStatus?.label, applicationStatusReason?.data],
  );

  const interviewQuery = useQuery({
    queryKey: [QUERY_KEYS.INTERVIEW, singleApplicationInList, singleApplicationInList?.id],
    queryFn: () =>
      singleApplicationInList ? InterviewApi.getInterview(singleApplicationInList?.id || "") : Promise.resolve([]),
  });

  const singleApplicationHasInterview = useMemo<boolean>(() => {
    return (
      // find the not deleted interviews in the future.
      interviewQuery.data?.find((interview) => !interview.deleted && interview.getInterviewEndDate() > moment()) !==
      undefined
    );
  }, [interviewQuery.data]);

  const interviewConfigurationQuery = useFetchInterviewsConfigurationByCampaignID({
    campaignId,
  });

  const resetStatusValues = useCallback(() => {
    setReasonWhy(undefined);
    setSendSMS(undefined);
  }, []);

  const interviewModal = useCallback(
    (_: string, submitAndCloseModal: () => unknown, cancelAndCloseModal: () => unknown, modalOpened: boolean) => {
      if (!interviewConfigurationQuery.data) {
        return null;
      }

      return (
        <ApplicationInterviewModal
          open={modalOpened}
          onConfirm={submitAndCloseModal}
          onCancel={cancelAndCloseModal}
          onSkip={submitAndCloseModal}
          campaignId={campaignId}
          applicationIDs={applications.map((application) => application.id)}
          interviewConfiguration={interviewConfigurationQuery.data}
          allowSkip={true}
        />
      );
    },
    [applications, campaignId, interviewConfigurationQuery.data],
  );

  const reasonWhyModal = useCallback(
    (
      selectedStatus: string,
      submitAndCloseModal: ModalProps["onConfirm"],
      cancelAndCloseModal: () => unknown,
      modalOpened: boolean,
    ) => {
      const canSetupSendSMS =
        withSendSMS &&
        STATUSES_WITH_OPTIONAL_SMS.includes(selectedStatus) &&
        triggers?.some((trigger) => trigger.status_id === findStatusFromLabel(selectedStatus)?.id);

      const customActions = (onConfirm: (() => void) | undefined, onClose: (() => void) | undefined) => {
        return (
          <>
            <Button
              onClick={() => {
                onClose?.();
              }}
              sx={{
                ...buttonStyle,
                color: COLOR_PALETTE.BASE[800],
                backgroundColor: "white",
                "&:hover": {
                  backgroundColor: "#F2F3F7",
                },
                border: `1px solid ${COLOR_PALETTE.BASE[800]}`,
              }}
            >
              {t("reasons.modal.cancel")}
            </Button>
            <Button
              onClick={() => {
                onConfirm?.();
              }}
              sx={{
                ...buttonStyle,
                marginRight: "0.5rem",
                color: "white",
                backgroundColor: COLOR_PALETTE.deepPurple,
                "&:hover": {
                  backgroundColor: COLOR_PALETTE.deepPurple,
                },
              }}
            >
              {t("reasons.modal.confirm")}
            </Button>
          </>
        );
      };

      return (
        <Modal
          scroll={"body"}
          modalIcon={<ContentPasteIcon />}
          title={t(`reasons.${selectedStatus}.modal.title`)}
          modalTitle={t(
            applications.length > 1
              ? `reasons.${selectedStatus}.modal.modal_title_bulk`
              : `reasons.${selectedStatus}.modal.modal_title`,
          )}
          onConfirm={submitAndCloseModal}
          onClose={() => {
            resetStatusValues();
            cancelAndCloseModal();
          }}
          options={{
            confirmProps: {
              disabled: !reasonWhyFormValid,
            },
          }}
          isOpen={modalOpened}
          customActions={customActions}
        >
          {applications.length > 1 && (
            <div style={{ marginBottom: "1rem" }}>
              <InfoBox title={""} level={"info"}>
                <Trans t={t} i18nKey="bulk_warning_box" values={{ count: applications.length }} />
              </InfoBox>
            </div>
          )}
          <UpdateReasonWhyForm
            sendSMS={sendSMS}
            setSendSMS={canSetupSendSMS ? setSendSMS : undefined}
            pendingStatus={selectedStatus}
            reasonWhy={reasonWhy}
            setReasonWhy={setReasonWhy}
            setFormValid={setReasonWhyFormValid}
          />
        </Modal>
      );
    },
    [
      resetStatusValues,
      findStatusFromLabel,
      reasonWhy,
      reasonWhyFormValid,
      sendSMS,
      t,
      triggers,
      withSendSMS,
      applications,
    ],
  );

  const sendSMSModal = useCallback(
    (
      selectedStatus: string,
      submitAndCloseModal: () => unknown,
      cancelAndCloseModal: () => unknown,
      modalOpened: boolean,
    ) => {
      const customActions = (onConfirm: (() => void) | undefined, onClose: (() => void) | undefined) => {
        return (
          <>
            <Button
              sx={{
                ...buttonStyle,
                color: COLOR_PALETTE.BASE[800],
                backgroundColor: "white",
                "&:hover": {
                  backgroundColor: "#F2F3F7",
                },
                border: `1px solid ${COLOR_PALETTE.BASE[800]}`,
              }}
              onClick={() => {
                setSendSMS(undefined);
                onClose?.();
              }}
            >
              {t("send sms.modal.cancel")}
            </Button>
            <Button
              color="error"
              onClick={() => {
                setSendSMS(false);
                onConfirm?.();
              }}
              sx={buttonStyle}
            >
              {t("send sms.modal.dont send")}
            </Button>
            <Button
              sx={{
                ...buttonStyle,
                marginRight: "0.5rem",
                color: "white",
                backgroundColor: COLOR_PALETTE.deepPurple,
                "&:hover": {
                  backgroundColor: COLOR_PALETTE.deepPurple,
                },
              }}
              onClick={() => {
                setSendSMS(true);
                onConfirm?.();
              }}
            >
              {t("send sms.modal.send")}
            </Button>
          </>
        );
      };

      return (
        <Modal
          scroll={"body"}
          modalIcon={<ContentPasteIcon />}
          modalTitle={t("send sms.modal.title")}
          onConfirm={submitAndCloseModal}
          onClose={() => {
            resetStatusValues();
            cancelAndCloseModal();
          }}
          customActions={customActions}
          options={{
            confirmProps: {
              disabled: !reasonWhyFormValid,
            },
          }}
          isOpen={modalOpened}
        >
          <SendSMSModalForm pendingStatus={selectedStatus} />
        </Modal>
      );
    },
    [resetStatusValues, reasonWhyFormValid, t],
  );

  const onChange = useCallback<(e: SelectChangeEvent<string | undefined>) => void>(
    (e) => {
      const statusLabel = e.target.value;
      onChangeProp(findStatusFromLabel(statusLabel ?? "new"), {
        reasonWhy: reasonWhy,
        sendSMS: sendSMS ?? true,
      });

      setSendSMS(undefined);
      setReasonWhy(undefined);
    },
    [findStatusFromLabel, onChangeProp, reasonWhy, sendSMS],
  );

  // Called when a new status is select. This function is responsible for checking if we should show a modal
  // to the user (diverse business logic).
  // For each modal, we return a function that takes the new selected status, two functions to submit the modal
  // and the modal status.
  const getModalToRender = useCallback(
    (
      selectedStatus: string,
      submitAndCloseModal: () => unknown,
      cancelAndCloseModal: () => unknown,
      modalOpened: boolean,
    ) => {
      if (!selectedStatus || selectedStatus === applicationStatus?.label) {
        return null;
      }

      const onSubmit = () => {
        submitAndCloseModal();
        const status = findStatusFromLabel(selectedStatus);
        if (onStatusConfirm && status) {
          onStatusConfirm?.(status, {
            reasonWhy: reasonWhy,
            sendSMS: sendSMS ?? true,
          });
        }
      };

      if (selectedStatus === "interview" && !singleApplicationHasInterview && interviewConfigurationQuery.data) {
        return interviewModal(selectedStatus, onSubmit, cancelAndCloseModal, modalOpened);
      }

      if (
        organizationHasFeature(ORGANIZATIONS_FEATURES.APPLICATION_STATUS_REASON) &&
        STATUSES_WITH_REASON.includes(selectedStatus)
      ) {
        return reasonWhyModal(selectedStatus, onSubmit, cancelAndCloseModal, modalOpened);
      }

      const canSetupSendSMS =
        withSendSMS &&
        STATUSES_WITH_OPTIONAL_SMS.includes(selectedStatus) &&
        triggers?.some((trigger) => trigger.status_id === findStatusFromLabel(selectedStatus)?.id);
      if (canSetupSendSMS) {
        return sendSMSModal(selectedStatus, onSubmit, cancelAndCloseModal, modalOpened);
      }

      return null;
    },
    [
      applicationStatus?.label,
      singleApplicationHasInterview,
      interviewConfigurationQuery.data,
      organizationHasFeature,
      withSendSMS,
      triggers,
      findStatusFromLabel,
      onStatusConfirm,
      reasonWhy,
      sendSMS,
      interviewModal,
      reasonWhyModal,
      sendSMSModal,
    ],
  );

  if (statusesQuery.data == null) {
    return null;
  }

  return (
    <SelectWithModal
      id="status_update_dropdown"
      label={customLabel ? <StatusSelectorLabel customLabel={customLabel} /> : null}
      initialValue={applicationStatus?.label ?? ""}
      renderValue={statusRenderValue}
      getModalToRender={getModalToRender}
      onChange={onChange}
      labelID="status-selector-label"
      dropDownScaleWidth={dropDownScaleWidth ?? 1.5}
      selectProps={{
        sx: {
          ".MuiInputBase-root": {
            margin: "0",
            padding: "0",
          },
        },
      }}
    >
      {statusesQuery.data.map((status: Status) => (
        <MenuItem key={status.label} value={status.label} id={`change_status_${status.label.replaceAll(" ", "_")}`}>
          <StatusChip status={status.label} />
          <RenderTriggers triggers={triggers} status={status} />
          <RenderATSIntegration atsIntegration={atsIntegration} status={status} />
        </MenuItem>
      ))}
    </SelectWithModal>
  );
};
