import { styled } from "@mui/material/styles";

import { palette } from "@theme";

export const Input = styled("input")`
  display: block;
  width: 100%;
  height: -webkit-calc(1.5em + 0.75rem + 2px);
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${palette.grey[600]};
  background-color: ${palette.background.paper};
  background-clip: padding-box;
  border: 1px solid ${palette.grey[600]};
  border-radius: 0.25rem;
`;

export const Label = styled("label")`
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.6rem;
  padding-top: 13px;
  letter-spacing: 0.025em;
  z-index: 100;
  font-size: 1.2em;
  color: ${palette.text.primary};
`;

export const Error = styled("p")`
  color: ${palette.error.main};
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0 0;
  width: 100%;
`;

export const Card = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background: #ffffff;
  box-shadow:
    22px 18px 11px rgba(5, 5, 102, 0.01),
    12px 10px 9px rgba(5, 5, 102, 0.02),
    5px 4px 7px rgba(5, 5, 102, 0.03),
    1px 1px 4px rgba(5, 5, 102, 0.04),
    0px 0px 0px rgba(5, 5, 102, 0.04);
  border-radius: 16px;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  overflow: hidden;
`;
