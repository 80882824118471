import { FC, ReactNode } from "react";

import { Box, ListItem } from "@mui/material";

interface Props {
  children?: ReactNode;
}

export const MenuItem: FC<Props> = ({ children }) => (
  <ListItem>
    <Box
      sx={{
        width: "100%",
        cursor: "pointer",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {children}
    </Box>
  </ListItem>
);
