import { FC, MouseEventHandler, ReactNode } from "react";

import { Button, Stack, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export interface CategoryProps {
  /**
   * An icon for the category, shown next to the title.
   */
  icon?: OverridableComponent<SvgIconTypeMap>;
  /**
   * The title of the category.
   */
  children: ReactNode;
  /**
   * The (optional) count of items in the category, shown under the title.
   */
  count?: number;
  /**
   * Whether the category is currently selected.
   */
  selected: boolean;
  /**
   * What happens when the category is clicked.
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  id?: string;
}

/**
 * Category is a tab-like filter, used to easily navigate between multiple groups of values in a list.
 * A list of Category elements can be wrapped using the {@link Categories} component.
 */
export const Category: FC<CategoryProps> = ({ icon: Icon, children, count, onClick, selected, id }) => (
  <Button
    id={id}
    className={selected ? "active" : ""}
    sx={(theme) => ({
      display: "flex",
      flexDirection: "column",
      height: "fit-content",
      borderRadius: theme.radius[2],
      cursor: "pointer",
      maxWidth: "100%",
      border: `1px solid ${theme.palette.color.BASE[300] as string}`,
      backgroundColor: theme.palette.color.BASE[0],
      color: theme.palette.text.mainInfo,
      padding: "1rem",
      flexShrink: 0,
      flexGrow: 1,
      "&:hover, &.active": {
        backgroundColor: theme.palette.color.deepPurple,
        borderColor: theme.palette.color.deepPurple,
      },
      "&:hover .MuiTypography-root, &.active .MuiTypography-root": {
        color: `${theme.palette.color.BASE[0] as string} !important`,
      },
      "&:hover svg, &.active svg": {
        fill: theme.palette.color.BASE[0],
      },
    })}
    onClick={onClick}
  >
    <Stack flexDirection="row" alignItems="center" gap="0.5rem">
      {Icon ? <Icon sx={(theme) => ({ width: "1.5rem", height: "1.5rem", fill: theme.palette.text.lowInfo })} /> : null}
      <Typography variant="tagsStrong" whiteSpace="nowrap">
        {children}
      </Typography>
    </Stack>
    {count == null ? null : (
      <Typography variant="tagsStrong" color={(theme) => theme.palette.text.mediumInfo}>
        {count}
      </Typography>
    )}
  </Button>
);

/**
 * Display zone for multiple {@link Category} elements.
 */
export const Categories: FC<{ children: ReactNode }> = ({ children }) => (
  <Stack
    flexDirection="row"
    gap={(theme) => theme.spacings[12]}
    alignSelf="stretch"
    flexShrink={0}
    flexWrap="nowrap"
    overflow="auto"
    maxWidth="100%"
  >
    {children}
  </Stack>
);
