import { useCallback, useMemo } from "react";

import { orderBy } from "lodash";
import { MRT_TableInstance } from "material-react-table";
import { useTranslation } from "next-i18next";

import { ManagedMaterialReactTableCategory } from "@components";
import { loadTranslations } from "@lib";
import { ApplicationListItem } from "@typings";

import { useFetchApplicationStatuses } from "@hooks/queries";

import { APPLICATION_STATUSES_FILTER } from "../constants";

export const useLeadsCategories = (
  applications: ApplicationListItem[],
  table: MRT_TableInstance<ApplicationListItem>,
) => {
  const { t } = useTranslation(["application-status"]);
  loadTranslations("application-status");

  const { getColumn, setColumnFilters } = table;

  const { data: statuses } = useFetchApplicationStatuses({ select: (result) => orderBy(result, ["rank"]) });

  const currentStatusFilter = getColumn("status").getFilterValue();

  const applicationsCountPerStatus = useMemo<Record<string, number>>(
    () =>
      (statuses ?? []).reduce(
        (acc, value) => ({
          ...acc,
          [value.label]: applications.reduce(
            (count, application) => (application.status === value.label ? count + 1 : count),
            0,
          ),
        }),
        {} as Record<string, number>,
      ),
    [applications, statuses],
  );

  const categories = useMemo<ManagedMaterialReactTableCategory[]>(
    () => [
      {
        value: "all",
        id: "status_card_all",
        selected: !currentStatusFilter,
        icon: APPLICATION_STATUSES_FILTER["all"].icon,
        displayValue: t(APPLICATION_STATUSES_FILTER["all"].label, { ns: "application-status" }),
        count: applications.length,
      },
      ...(statuses?.map((status) => ({
        value: status.label,
        id: `status_card_${status.label}`,
        selected: Array.isArray(currentStatusFilter) && currentStatusFilter.includes(status.label),
        icon: APPLICATION_STATUSES_FILTER[status.label].icon,
        displayValue: t(APPLICATION_STATUSES_FILTER[status.label].label, { ns: "application-status" }),
        count: applicationsCountPerStatus[status.label],
      })) ?? []),
    ],
    [applications.length, applicationsCountPerStatus, currentStatusFilter, statuses, t],
  );

  const onCategoryChange = useCallback(
    (category: string) => {
      setColumnFilters((prevState) => [
        ...(prevState ?? []).filter((filter) => filter.id !== "status"),
        ...(category === "all" ? [] : [{ id: "status", value: category }]),
      ]);
    },
    [setColumnFilters],
  );

  return { categories, onCategoryChange };
};
