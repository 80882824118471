import { FC } from "react";

import { MRT_Cell, MRT_Row } from "material-react-table";

import { StatusChip } from "@components";
import { ApplicationListItem } from "@typings";

export const StatusCell: FC<{ row: MRT_Row<ApplicationListItem>; cell: MRT_Cell<ApplicationListItem> }> = ({
  cell,
  row,
}) => <StatusChip status={String(cell.getValue())} statusReason={row.original.status_reason} />;
