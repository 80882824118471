import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";

/**
 * Update a state from a Promise, and use internal mitigation mechanisms to prevent race conditions.
 */
export const usePromiseUpdater = <T>(setState: Dispatch<SetStateAction<T | undefined>>, buffer: number = 100) => {
  const timer = useRef<NodeJS.Timeout>();
  const [, setPromise] = useState<Promise<void>>(Promise.resolve());

  return useCallback(
    (value: () => Promise<SetStateAction<T | undefined>>) => {
      clearTimeout(timer.current);

      // Schedule an update using the buffer value for timing.
      timer.current = setTimeout(() => {
        setPromise((promise) => promise.then(async () => setState(await value())));
      }, buffer);
    },
    [buffer, setState],
  );
};
