import { FC, useMemo, useState } from "react";

import { MRT_Row } from "material-react-table";

import { Box, Stack, Tooltip } from "@mui/material";

import { ListJobItem } from "@typings";

export const NameCell: FC<{ row: MRT_Row<ListJobItem> }> = ({ row }) => {
  const [titleRef, setTitleRef] = useState<HTMLDivElement | null>(null);

  const isTooltipVisible = useMemo(() => titleRef?.innerText !== row.original.title, [titleRef, row.original.title]);

  const content = (
    <Stack flexDirection="column" gap={(theme) => theme.spacings[4]}>
      <Box
        ref={setTitleRef}
        overflow="hidden"
        textOverflow="ellipsis"
        display="-webkit-box"
        fontSize="1rem"
        fontWeight={700}
        lineHeight="150%"
        color={(theme) => theme.palette.color.BASE[800]}
        sx={{
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        }}
      >
        {row.original.title}
      </Box>
    </Stack>
  );

  return isTooltipVisible ? (
    <Tooltip title={row.original.title} placement="top-start">
      {content}
    </Tooltip>
  ) : (
    <>{content}</>
  );
};
