import { FC, useMemo } from "react";

import { MRT_Row } from "material-react-table";
import moment from "moment";
import { useTranslation } from "next-i18next";

import { Tooltip, TooltipProps } from "@mui/material";

import { loadTranslations } from "@lib";
import { ApplicationListItem } from "@typings";

import { RedDot } from "./red-dot";

export const getApplicationName = (row: ApplicationListItem) =>
  (row.candidate && `${row.candidate.first_name} ${row.candidate.last_name}`) || "";

export const NameCell: FC<{ row: MRT_Row<ApplicationListItem> }> = ({ row }) => {
  const { t } = useTranslation(["leads"]);
  loadTranslations("leads");

  const markAsNotOpened =
    !row.original.last_opened_at || moment(row.original.last_opened_at) < moment(row.original.last_interaction_date);

  const toolTipComponentProps = useMemo<TooltipProps["componentsProps"]>(
    () => ({
      tooltip: {
        sx: (theme) => ({
          color: "white",
          bgcolor: theme.palette.background.darker,
          textAlign: "center",
        }),
      },
    }),
    [],
  );

  if (!markAsNotOpened) {
    return <span>{getApplicationName(row.original)}</span>;
  }

  return (
    <Tooltip
      componentsProps={toolTipComponentProps}
      title={t("red_dot_tooltip", { ns: "leads" })}
      placement="top"
      arrow
    >
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <RedDot />
        <span>{getApplicationName(row.original)}</span>
      </div>
    </Tooltip>
  );
};
