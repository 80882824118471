import { useCallback, useMemo } from "react";

import { cloneDeep, uniq } from "lodash";

import { ApplicationApi, AuthProxyAPI, JobsApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { QueryClient, UseQueryResult, useQueries, useQuery } from "@tanstack/react-query";
import { Application, ApplicationListItem, Candidate, CandidatesObject, Job } from "@typings";
import { Logger } from "@utils";

const useParsApplicationsList = (applicationsQuery: UseQueryResult<Application[], unknown>) => {
  // Filter unique candidates IDs.
  const candidateIDs = useMemo<string[]>(
    () => uniq(applicationsQuery.data?.map((application) => application.candidate_id)),
    [applicationsQuery.data],
  );

  // Filter unique job IDs.
  const jobIDs = useMemo(
    () => uniq(applicationsQuery.data?.map((application) => application.job_id)),
    [applicationsQuery.data],
  );

  const candidatesSelect = useCallback(
    (candidates: Candidate[]): CandidatesObject =>
      candidates.reduce((acc, candidate) => (candidate.id ? { ...acc, [candidate.id]: candidate } : acc), {}),
    [],
  );

  const candidatesQuery = useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS_CANDIDATES, candidateIDs],
    queryFn: () => AuthProxyAPI.searchCandidates(candidateIDs),
    enabled: candidateIDs.length > 0,
    select: candidatesSelect,
  });

  const combineJobs = useCallback(
    (results: UseQueryResult<Job>[]) => ({
      data: results.reduce((res, result) => (result.isSuccess ? { ...res, [result.data.id]: result.data } : res), {}),
      isPending: results.some((result) => result.isPending),
      isLoading: results.some((result) => result.isLoading),
    }),
    [],
  );

  const jobs = useQueries({
    queries: jobIDs.map((jobId) => ({ queryKey: [QUERY_KEYS.JOBS, jobId], queryFn: () => JobsApi.get(jobId) })),
    combine: combineJobs,
  });

  const applications = useMemo((): ApplicationListItem[] => {
    if (!applicationsQuery.data) return [];

    return applicationsQuery.data.map((application) => ({
      id: application.id,
      organization_name: application.organization_name,

      campaign_id: application.campaign_id,

      status: application.status,
      status_reason: application.status_reason,
      last_interaction_date: application.last_interaction_date,
      created_at: application.created_at,
      last_opened_at: application.last_opened_at,

      candidate: candidatesQuery.data?.[application.candidate_id],
      job: jobs.data[application.job_id],

      answers: application.answers,
    }));
  }, [applicationsQuery.data, candidatesQuery.data, jobs]);

  return {
    applications,
    isPending: applicationsQuery.isPending || candidatesQuery.isPending || jobs.isPending,
    isLoading: applicationsQuery.isLoading || candidatesQuery.isLoading || jobs.isLoading,
    isSuccess: applicationsQuery.isSuccess && candidatesQuery.isSuccess,
  };
};

export const useFetchCampaignApplicationsList = (campaignID: string | undefined) => {
  const applicationsQuery = useQuery<Application[], unknown>({
    queryKey: [QUERY_KEYS.APPLICATIONS_LIST, { campaignID }],
    queryFn: () => ApplicationApi.list({ campaign_id: campaignID }),
    enabled: !!campaignID,
    placeholderData: [],
  });

  return useParsApplicationsList(applicationsQuery);
};

export const useFetchJobApplicationsList = (jobTitle: string | undefined) => {
  const applicationsQuery = useQuery<Application[], unknown>({
    queryKey: [QUERY_KEYS.APPLICATIONS_LIST, { jobTitle }],
    queryFn: () => ApplicationApi.list({ job_title: jobTitle, campaign_id: "" }),
    enabled: !!jobTitle,
    placeholderData: [],
  });

  return useParsApplicationsList(applicationsQuery);
};

export type PartialApplication = Partial<Omit<Application, "id">> & {
  id: string;
};

export const updateApplicationsCache = (
  queryClient: QueryClient,
  updater: PartialApplication | string | (PartialApplication | string)[],
) => {
  const cachedApplications = queryClient.getQueriesData<Application[]>({
    predicate: (query) => query.queryKey[0] === QUERY_KEYS.APPLICATIONS_LIST,
  });

  cachedApplications.forEach(([queryKey, applications]) => {
    if (!applications) return;

    const parsedNewApplications = Array.isArray(updater) ? updater : [updater];

    // Check if new applications belong to this query.
    parsedNewApplications.forEach((newApplication) => {
      if (typeof newApplication === "string") {
        queryClient.removeQueries({
          queryKey: [QUERY_KEYS.APPLICATIONS, newApplication],
        });
        const newApplications = applications.filter((application) => application.id !== newApplication);
        queryClient.setQueryData(queryKey, newApplications);
        return;
      }

      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.APPLICATIONS, newApplication.id],
        })
        .catch(Logger.error);

      const matchingApplicationIndex = applications.findIndex((application) => application.id === newApplication.id);
      if (matchingApplicationIndex === -1) return;

      const newApplications = [...applications];
      newApplications[matchingApplicationIndex] = Object.assign(
        cloneDeep(newApplications[matchingApplicationIndex]),
        newApplication,
      );
      queryClient.setQueryData(queryKey, newApplications);
    });
  });
};
