import { FC } from "react";

import { MRT_Row } from "material-react-table";

import { ApplicationScoringChip } from "@components";
import { ApplicationListItem } from "@typings";

export const CompatibilityCell: FC<{ row: MRT_Row<ApplicationListItem>; campaignID: string }> = ({
  row,
  campaignID,
}) => (
  <ApplicationScoringChip
    renderChip={false}
    applicationID={row.original.id}
    renderChipForLowPercentages={false}
    campaignID={campaignID}
  />
);
