import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";

import {
  ManagedColumnFilter,
  ManagedMaterialReactTable,
  ValuesTranslator,
  useManagedMaterialReactTable,
} from "@components";
import { loadTranslations } from "@lib";
import { useApplicationsStore } from "@stores";
import { ApplicationListItem, Job, SourcingCampaign } from "@typings";

import {
  useCampaignQuestionsQuery,
  useFetchCampaignApplicationsList,
  useFetchJobApplicationsList,
  useScoringEnabledQuery,
} from "@hooks/queries";

import { RowActions } from "./cells";
import { INITIAL_COLUMNS_VISIBILITY } from "./constants";
import { useAdvancedFilters, useLeadsCategories, useLeadsGroupActions, useOnRowClick } from "./hooks";
import { useLeadsColumns } from "./leads-columns";

const onBeforeSaveColumnFilters = (value: ManagedColumnFilter[] | undefined, destination: "url" | "localStorage") => {
  if (value == null) {
    return [];
  }

  if (destination === "url") {
    return value.filter((filter) => !filter.id.startsWith("question_") && filter.id !== "status");
  }

  return value.filter((filter) => filter.id !== "status");
};

export type LeadsProps =
  | {
      sourcingCampaign: SourcingCampaign;
      job?: never;
    }
  | {
      sourcingCampaign?: never;
      job: Job;
    };

export const Leads: FC<LeadsProps> = ({ sourcingCampaign, job }) => {
  const { t } = useTranslation(["application-status", "application-scoring", "applications-advanced-filters", "leads"]);
  loadTranslations("application-status");
  loadTranslations("applications-advanced-filters");
  loadTranslations("leads");

  const setCurrentApplicationID = useApplicationsStore((state) => state.setCurrentApplicationID);
  const setApplicationsID = useApplicationsStore((state) => state.setApplicationsID);

  const { data: session } = useSession();
  const onRowClick = useOnRowClick({ campaignID: sourcingCampaign?.campaign_id, jobID: job?.id });

  const translateColumn = useCallback(
    (columnID: string) =>
      columnID.startsWith("question_") ? columnID.split("question_")[1] : t(columnID, { ns: "leads" }),
    [t],
  );

  const translateColumnValues = useMemo<ValuesTranslator>(
    () => new Map([["compatibility", (value: string) => t(value, { ns: "application-scoring" })]]),
    [t],
  );

  const scoringEnabledQuery = useScoringEnabledQuery(sourcingCampaign?.campaign_id);
  const questionsQuery = useCampaignQuestionsQuery(sourcingCampaign?.campaign_id, session);
  const { applications: campaignApplications, isLoading: campaignIsLoading } = useFetchCampaignApplicationsList(
    sourcingCampaign?.campaign_id,
  );
  const { applications: jobApplications, isLoading: jobIsLoading } = useFetchJobApplicationsList(job?.title);
  const applications = useMemo(
    () => (sourcingCampaign ? campaignApplications : jobApplications),
    [campaignApplications, jobApplications, sourcingCampaign],
  );
  const isLoading = campaignIsLoading || jobIsLoading;

  const columns = useLeadsColumns(sourcingCampaign);

  const [filterableColumns, setFilterableColumns] = useState<string[]>(["job_title", "job_location"]);
  const table = useManagedMaterialReactTable<ApplicationListItem>({
    seizaFilters: {
      cacheKey: sourcingCampaign ? `leads_campaign_${sourcingCampaign.campaign_id}` : `leads_job_${job.id}`,
      initialColumnsVisibility: INITIAL_COLUMNS_VISIBILITY,
      onBeforeSaveColumnFilters,
    },
    columns,
    data: applications,
    state: { isLoading },
    initialState: {
      pagination: { pageSize: 50, pageIndex: 0 },
      columnPinning: { right: ["mrt-row-actions"] },
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableRowSelection: true,
    enableColumnFilters: false,
    enableGlobalFilter: true,
    enableHiding: false,
    enableRowActions: true,
    enableTopToolbar: false,
    enableFacetedValues: true,
    muiTableContainerProps: { id: "application_list" },
    muiTableBodyRowProps: ({ row }) => ({
      id: `application_item_${row.original.id}`,
      className: "application_item",
    }),
    muiTableBodyCellProps: ({ cell, row, table }) => ({
      onClick: () => {
        if (cell.id !== "mrt-row-select" && !cell.id.includes("mrt-row-actions")) onRowClick(table, row.original.id);
      },
    }),
    renderRowActions: ({ table, row }) => (
      <RowActions row={row} disabled={table.getSelectedRowModel().rows.length > 0} />
    ),
  });

  const { setColumnOrder, setColumnVisibility } = table;

  const { categories, onCategoryChange } = useLeadsCategories(applications, table);

  const { groupActions, groupActionsModals } = useLeadsGroupActions(
    {
      campaignID: sourcingCampaign?.campaign_id,
      jobID: job?.id,
    },
    table,
  );

  const { advancedFilters } = useAdvancedFilters(table);

  // Reset the current application when the campaign is changed.
  useEffect(() => {
    setCurrentApplicationID(null);
    setApplicationsID([]);
  }, [setApplicationsID, setCurrentApplicationID]);

  // Update filters if scoring is enabled.
  useEffect(() => {
    if (scoringEnabledQuery.data) {
      setFilterableColumns((prevState) => {
        // Don't update state if the compatibility column is already included.
        if (prevState.includes("compatibility")) return prevState;
        return [...prevState, "compatibility"];
      });
      setColumnVisibility((prevState) => {
        // Don't update state if the compatibility column is already included.
        if (prevState?.compatibility != null) return prevState;
        return { ...prevState, compatibility: true };
      });
    }
  }, [scoringEnabledQuery.data, setColumnVisibility]);

  // Update filters when new questions are available.
  useEffect(() => {
    if (questionsQuery.data) {
      const mappedColumns = questionsQuery.data.map((question) => `question_${question.label}`);
      setFilterableColumns((prevState) => [
        ...prevState,
        ...mappedColumns.filter((column) => !prevState.includes(column)),
      ]);
      setColumnVisibility((prevState) => {
        const newColumns = mappedColumns.filter((column) => prevState[column] == null);
        // No columns were added. Don't trigger React state update.
        if (newColumns.length === 0) return prevState;
        return { ...prevState, ...Object.fromEntries(newColumns.map((column) => [column, false])) };
      });
    }
  }, [questionsQuery.data, setColumnVisibility]);

  // Ensure that the columns are in the correct order.
  useEffect(() => {
    setColumnOrder(["mrt-row-select", ...columns.map((column) => column.id!), "mrt-row-actions"]);
  }, [columns, setColumnOrder]);

  return (
    <>
      <ManagedMaterialReactTable
        table={table}
        title={sourcingCampaign?.name ?? job?.title ?? ""}
        categories={categories}
        onCategoryChange={onCategoryChange}
        groupedActions={groupActions}
        filterableColumns={filterableColumns}
        advancedFilters={advancedFilters}
        translateColumn={translateColumn}
        translateColumnValues={translateColumnValues}
        globalFilter
        showActiveFilters
      />

      {groupActionsModals}
    </>
  );
};
