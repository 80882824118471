import Head from "next/head";

import { FC, useMemo } from "react";

import { AxiosError } from "axios";
import { useTranslation } from "next-i18next";

import { Box } from "@mui/material";

import { JobsApi, SourcingCampaignApi } from "@api";
import { CallInProgressAlert, Leads, LeadsProps, NavigationBarHeader, Spinner, TelnyxWrapper } from "@components";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { useQuery } from "@tanstack/react-query";
import { Job, SourcingCampaign } from "@typings";

import { CallBox } from "../application/application-interview-modals/call-box";

export type LeadsWrapperProps =
  | {
      sourcingCampaignID: string;
      jobID?: never;
    }
  | {
      sourcingCampaignID?: never;
      jobID: string;
    };

export const LeadsWrapper: FC<LeadsWrapperProps> = ({ sourcingCampaignID, jobID }) => {
  const { t } = useTranslation(["table"]);
  loadTranslations("table");

  const sourcingCampaignQuery = useQuery<SourcingCampaign, AxiosError>({
    queryKey: [QUERY_KEYS.SOURCING_CAMPAIGNS, sourcingCampaignID],
    queryFn: () => SourcingCampaignApi.get(sourcingCampaignID ?? ""),
    enabled: !!sourcingCampaignID,
  });

  const jobQuery = useQuery<Job, AxiosError>({
    queryKey: [QUERY_KEYS.JOBS, jobID],
    queryFn: () => JobsApi.get(jobID ?? ""),
    enabled: !!jobID,
  });

  const leadsProps = useMemo<LeadsProps>(
    () =>
      ({
        sourcingCampaign: sourcingCampaignQuery.data,
        job: jobQuery.data,
      }) as LeadsProps,
    [jobQuery.data, sourcingCampaignQuery.data],
  );

  if (sourcingCampaignQuery.data == null && jobQuery.data == null) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Spinner style={{ position: "relative", left: 0, top: 0 }} />
      </div>
    );
  }

  if (sourcingCampaignQuery.isError || jobQuery.isError) {
    throw sourcingCampaignQuery.error ?? jobQuery.error;
  }

  if (!sourcingCampaignQuery.isSuccess && !jobQuery.isSuccess) {
    throw new Error("error while loading data");
  }

  const id = sourcingCampaignID ?? jobID;
  const name = sourcingCampaignQuery.data?.name ?? jobQuery.data?.title ?? "...";
  const prefixPath = sourcingCampaignID ? "sourcing-operations" : "jobs";

  return (
    <>
      <NavigationBarHeader pathName={sourcingCampaignID ? "/" : "/jobs"} />
      <NavigationBarHeader
        pathName={`/${prefixPath}/[id]/applications`}
        redirectTo={`/${prefixPath}/${id ?? ""}/applications`}
        options={{ operationName: name }}
      />

      <Box padding="var(--space-24) 2rem 2rem 2rem">
        <Head>
          <title>{sourcingCampaignID ? t("head.sourcingCampaign") : t("head.job")}</title>
        </Head>
        {/* Display back to campaigns button */}
        <TelnyxWrapper>
          <CallInProgressAlert />
          <CallBox />
          <Leads {...leadsProps} />
        </TelnyxWrapper>
      </Box>
    </>
  );
};
