import { useCallback, useMemo } from "react";

import { useTranslation } from "next-i18next";

import { ScoringAPI } from "@api";
import { ApplicationScoreGetterToLabel } from "@components";
import { QUERY_KEYS } from "@constants";
import { useQuery } from "@tanstack/react-query";

export const useScoringRowParams = (campaignID: string | undefined) => {
  const { t } = useTranslation(["application-scoring"]);

  const scoringParams = useMemo(
    () => ({
      top_candidate: {
        label: t("top_candidate", { ns: "application-scoring" }),
        rank: 4,
      },
      high: {
        label: t("high", { ns: "application-scoring" }),
        rank: 3,
      },
      medium: {
        label: t("medium", { ns: "application-scoring" }),
        rank: 2,
      },
      low: {
        label: t("low", { ns: "application-scoring" }),
        rank: 1,
      },
      no_score: {
        label: t("no_score", { ns: "application-scoring" }),
        rank: 0,
      },
    }),
    [t],
  );

  const { data: scoreData } = useQuery({
    queryKey: [QUERY_KEYS.APPLICATION_ALL_SCORES, campaignID],
    queryFn: () => ScoringAPI.getAllScores(campaignID ?? ""),
    staleTime: Infinity,
    enabled: !!campaignID,
  });

  const getRowParams = useCallback<(applicationID: string) => { label: string; rank: number }>(
    (applicationID: string) => {
      if (scoreData == undefined) {
        return scoringParams.no_score;
      }
      const score: number | null = scoreData[applicationID] ? scoreData[applicationID] : null;
      return scoringParams[ApplicationScoreGetterToLabel(score)] || scoringParams.no_score;
    },
    [scoreData, scoringParams],
  );

  return { getRowParams };
};
