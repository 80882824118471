import { useCallback } from "react";

import { useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { Stack, Typography } from "@mui/material";

import { IOSSwitch, SettingsPageForm } from "@components";
import { loadTranslations } from "@lib";
import { ORGANIZATIONS_FEATURES } from "@typings";

import { useConfirm, useUserOrganization } from "@hooks";
import { useOrganizationFeatures } from "@hooks/queries";

export const SettingsTab = () => {
  const { t } = useTranslation(["organization-settings"]);
  loadTranslations("organization-settings");

  const organization = useUserOrganization();

  const { hasFeature, toggleFeature } = useOrganizationFeatures(organization.data?.group_id ?? "");

  const [ToggleFeatureDialog, confirmToggleFeature] = useConfirm({
    title: t("organization-settings:settings.modals.features.title"),
    modalTitle: t("organization-settings:settings.modals.features.modalTitle"),
    message: t("organization-settings:settings.modals.features.message"),
  });

  const handleFeatureToggle = useCallback(
    (featureName: string, enabled: boolean) => {
      confirmToggleFeature().then((ans) => {
        if (ans) {
          toggleFeature(featureName, enabled, {
            onSuccess: () => {
              toast.success(t("organization-settings:settings.modals.features.success"));
            },
            onError: () => {
              toast.error(t("organization-settings:settings.modals.features.error"));
            },
          });
        }
      });
    },
    [confirmToggleFeature, t, toggleFeature],
  );

  return (
    <SettingsPageForm title={t("organization-settings:settings.title")}>
      {Object.values(ORGANIZATIONS_FEATURES).map((feature) => (
        <Stack
          gap="var(--size-12)"
          alignSelf="stretch"
          alignItems="center"
          flexDirection="row"
          width="100%"
          justifyContent="space-between"
          key={feature}
        >
          <Typography fontSize="var(--font-size-100)" color="var(--color-palette-base-800)" fontWeight={400}>
            {t(`organization-settings:settings.features.values.${feature}`)}
          </Typography>
          <IOSSwitch checked={hasFeature(feature)} onChange={(e) => handleFeatureToggle(feature, e.target.checked)} />
        </Stack>
      ))}
      <ToggleFeatureDialog />
    </SettingsPageForm>
  );
};
