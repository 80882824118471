import {
  Cancel as CancelIcon,
  HourglassTop as HourglassTopIcon,
  PersonSearch as PersonSearchIcon,
  PhoneMissed as PhoneMissedIcon,
  Star as StarIcon,
  Work as WorkIcon,
} from "@mui/icons-material";
import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";

import { palette } from "@theme";
import { StatusCategories } from "@typings";

type StatusCategoriesKeys = keyof typeof StatusCategories;

type ApplicationCategoriesStyle = {
  sx: SxProps<Theme>;
  icon?: React.JSX.Element;
};

export const ApplicationCategoryToStyle: Record<StatusCategoriesKeys, ApplicationCategoriesStyle> = {
  new: {
    sx: {
      backgroundColor: palette.success.light || "",
      color: palette.success.main || "",
    },
    icon: <PersonSearchIcon sx={{ width: "1rem", height: "1rem" }} />,
  },
  processing: {
    sx: {
      backgroundColor: palette.color.pastelPurple,
      color: palette.text.mainInfo,
    },
    icon: <HourglassTopIcon sx={{ width: "1rem", height: "1rem", fill: palette.text.mainInfo }} />,
  },
  finalized: {
    sx: {
      backgroundColor: palette.color.deepPurple,
      color: palette.color.BASE[0],
    },
    icon: <WorkIcon sx={{ width: "1rem", height: "1rem", fill: palette.color.BASE[0] }} />,
  },
  closed: {
    sx: {
      backgroundColor: palette.text.lowInfo,
      color: palette.color.BASE[0],
    },
    icon: <CancelIcon sx={{ width: "1rem", height: "1rem", fill: palette.color.BASE[0] }} />,
  },
  did_not_answer: {
    sx: {
      backgroundColor: "#FDD5D1",
      color: palette.text.mainInfo,
    },
    icon: <PhoneMissedIcon sx={{ width: "1rem", height: "1rem", fill: palette.text.mainInfo }} />,
  },
  talent_pool: {
    sx: {
      backgroundColor: palette.color.deepPurple,
      color: palette.color.BASE[0],
    },
    icon: <StarIcon sx={{ width: "1rem", height: "1rem", fill: palette.color.BASE[0] }} />,
  },
};
