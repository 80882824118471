import { useEffect } from "react";

import { User } from "next-auth";
import { useSession } from "next-auth/react";

import { openobserveLogs } from "@openobserve/browser-logs";
import { openobserveRum } from "@openobserve/browser-rum";

const options = {
  clientToken: process.env.NEXT_PUBLIC_OTEL_RUM_TOKEN as string,
  applicationId: "platform-ui",
  site: "oo.seiza.co",
  service: process.env.NEXT_PUBLIC_OTEL_EXPORTER_SERVICE_NAME as string,
  env: process.env.NEXT_PUBLIC_OTEL_EXPORTER_STREAM_NAME as string,
  version: "0.0.1",
  organizationIdentifier: "default",
  insecureHTTP: false,
  apiVersion: "v1",
};

openobserveRum.init({
  applicationId: options.applicationId, // required, any string identifying your application
  clientToken: options.clientToken,
  site: options.site,
  organizationIdentifier: options.organizationIdentifier,
  service: options.service,
  env: options.env,
  version: options.version,
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  apiVersion: options.apiVersion,
  insecureHTTP: options.insecureHTTP,
  defaultPrivacyLevel: "allow", // 'allow' or 'mask-user-input' or 'mask'. Use one of the 3 values.
});

openobserveLogs.init({
  clientToken: options.clientToken,
  site: options.site,
  organizationIdentifier: options.organizationIdentifier,
  service: options.service,
  env: options.env,
  version: options.version,
  forwardErrorsToLogs: true,
  insecureHTTP: options.insecureHTTP,
  apiVersion: options.apiVersion,
});

export function startRum(user: User) {
  openobserveRum.setUser({
    id: user.sub,
    name: `${user.given_name} ${user.family_name}`,
    email: user.email,
  });

  openobserveRum.startSessionReplayRecording();
}

export const Rum = () => {
  const { data: session } = useSession();

  useEffect(() => {
    if (session?.user && process.env.NEXT_PUBLIC_OTEL_RUM_ENABLED === "true") {
      startRum(session.user);
    }
  }, [session]);

  return <></>;
};
