import React, { Fragment, ReactNode, useCallback } from "react";

import { useTranslation } from "next-i18next";

import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import { Button, Menu, MenuItem, Typography } from "@mui/material";

import { loadTranslations } from "@lib";

export interface GroupAction<Element> {
  display: ReactNode | ((selected: Element[]) => ReactNode);
  onClick: (selected: Element[]) => void;
  enabled?: boolean;
}

export interface GroupActionsProps<Element> {
  selected: Element[];
  actions: GroupAction<Element>[];
}

export const GroupActions = <Element,>({ selected, actions }: GroupActionsProps<Element>) => {
  const { t } = useTranslation(["table"]);
  loadTranslations("table");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const opened = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  if (!selected.length) {
    return null;
  }

  return (
    <>
      <Button
        id="bulk_actions_button"
        aria-controls={opened ? "actions-button" : undefined}
        aria-haspopup="true"
        aria-expanded={opened ? "true" : undefined}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        variant="contained"
        color="deepPurple"
        sx={{ border: 0 }}
      >
        {t("groupActions.title")}
      </Button>
      <Menu
        id="actions-button"
        anchorEl={anchorEl}
        open={opened}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "actions-button" }}
      >
        {actions.map(({ display, onClick, enabled }, index) => {
          if (enabled === false) return <Fragment key={index} />;
          return (
            <MenuItem
              key={index}
              onClick={() => {
                onClick(selected);
                handleClose();
              }}
            >
              <Typography fontSize="1rem" variant="body" color={(theme) => theme.palette.text.mainInfo}>
                {typeof display === "function" ? display(selected) : display}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
