import { Box } from "@mui/material";

export const RedDot = () => (
  <Box
    component="span"
    sx={(theme) => ({
      backgroundColor: theme.palette.color.ALERT_RED[400],
      borderRadius: "50%",
      display: "inline-block",
      margin: "0px -1px",
      padding: "4px",
      position: "relative",
      right: "15px",
    })}
  />
);
