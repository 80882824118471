import { Session } from "next-auth";

import { ApplicationApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { useQuery } from "@tanstack/react-query";

export const useCampaignQuestionsQuery = (campaignID: string | undefined, session: Session | null) =>
  useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QUERY_KEYS.CAMPAIGN_APPLICATIONS_QUESTIONS, campaignID],
    queryFn: () =>
      ApplicationApi.listQuestions({
        campaign_id: campaignID,
        ...(session?.user.groups ? { organization_names: [session.user.groups[0]] } : {}),
      }),
    enabled: !!campaignID && !!session,
  });
