import { useRouter } from "next/router";

import { useCallback } from "react";

import { useApplicationsStore } from "@stores";
import { Logger } from "@utils";

export const useOnRowClick = () => {
  const { push: routerPush, pathname } = useRouter();
  const setCurrentJobID = useApplicationsStore((state) => state.setCurrentJobID);

  return useCallback<(jobID: string) => void>(
    (jobID) => {
      setCurrentJobID(jobID ?? null);

      routerPush({ pathname: `${pathname}/${jobID}/applications` }, undefined, {
        shallow: false,
      }).catch(Logger.error);
    },
    [pathname, routerPush, setCurrentJobID],
  );
};
