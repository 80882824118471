import { FC } from "react";

import { MRT_Row } from "material-react-table";

import { ApplicationListItem } from "@typings";
import { parsePhoneNumber } from "@utils";

export const PhoneCell: FC<{ row: MRT_Row<ApplicationListItem> }> = ({ row }) => (
  <>{row.original.candidate?.phone ? parsePhoneNumber(row.original.candidate.phone) : ""}</>
);
