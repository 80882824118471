import Image from "next/image";
import NextLink from "next/link";
import { useRouter } from "next/router";

import { FC, ReactNode, useMemo } from "react";

import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";

import { Box, List, Drawer as MuiDrawer, Stack, Tooltip, Typography, styled, useTheme } from "@mui/material";

import { ActiveLink, MenuItem } from "@components";
import { UserPermissions } from "@constants";
import { loadTranslations } from "@lib";
import { checkPermissions } from "@utils";

import { useUserPermissions } from "@hooks";

type DashboardLayoutProps = {
  title?: string;
  children: ReactNode;
};

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(() => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflowX: "hidden",
  width: "74px",
  "& .MuiDrawer-paper": {
    borderWidth: "0",
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
    overflowX: "hidden",
    width: "74px",
  },
}));

interface CustomNavLinkProps {
  iconSrc: string;
  iconAlt: string;
  tooltip: string;
  href: string;
}

const CustomNavLink: FC<CustomNavLinkProps> = ({ iconSrc, iconAlt, href, tooltip }) => {
  const theme = useTheme();
  const router = useRouter();

  const isActive = router.pathname === href;

  return (
    <MenuItem>
      <ActiveLink active={isActive} href={href}>
        <Tooltip
          title={
            <Typography
              sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                fontSize: "1rem",
              }}
            >
              {tooltip}{" "}
            </Typography>
          }
          placement="right-end"
        >
          <Image
            src={iconSrc}
            alt={iconAlt}
            style={{ marginTop: "0.5rem" }}
            className="menu-item-icon"
            color="primary"
            width={30}
            height={30}
          />
        </Tooltip>
      </ActiveLink>
    </MenuItem>
  );
};

export function DashboardLayout({ children }: DashboardLayoutProps) {
  const { t } = useTranslation(["header", "common", "support"]);
  loadTranslations("header");
  loadTranslations("common");
  loadTranslations("support");

  const { data: session } = useSession();
  const userPermissions = useUserPermissions(session?.accessToken);

  const canCreateCampaign = useMemo(
    () => checkPermissions(userPermissions, [UserPermissions.SourcingCampaignsPermissions.Write]),
    [userPermissions],
  );

  return (
    <Stack direction="row" flexWrap="nowrap" minWidth="40rem" height="100%">
      <Drawer sx={{ flexShrink: 0 }} variant="permanent" anchor="left">
        <Box sx={{ display: "flex", alignItem: "center", justifyContent: "center", marginBottom: "2.5rem" }}>
          <NextLink href="/" passHref>
            <Image src="/assets/logo-seiza.png" alt="logo" width="41" height="40" />
          </NextLink>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
          <List>
            {canCreateCampaign && (
              <CustomNavLink
                iconSrc="/assets/nav/create-campaign-icon.png"
                iconAlt="create-campaign"
                tooltip={t("common:add")}
                href="/sourcing-campaign"
              />
            )}
            <CustomNavLink
              iconSrc="/assets/nav/campaign-page-logo.png"
              iconAlt="campaign-page"
              tooltip={t("common:list")}
              href="/"
            />
            <CustomNavLink
              iconSrc="/assets/nav/jobs-page-logo.png"
              iconAlt="jobs-page"
              tooltip={t("common:jobs")}
              href="/jobs"
            />
          </List>
        </Box>
      </Drawer>
      <Stack direction="column" flexGrow={1} padding="0!important" minWidth={0} height="100%">
        {children}
      </Stack>
    </Stack>
  );
}
