import { useRouter } from "next/router";

import { useCallback } from "react";

import { MRT_TableInstance } from "material-react-table";

import { useApplicationsStore } from "@stores";
import { ApplicationListItem } from "@typings";
import { Logger } from "@utils";

export interface UseOnRowClickProps {
  campaignID?: string;
  jobID?: string;
}

export const useOnRowClick = ({ campaignID, jobID }: UseOnRowClickProps) => {
  const { push: routerPush, pathname } = useRouter();

  const setCurrentApplicationID = useApplicationsStore((state) => state.setCurrentApplicationID);
  const setApplicationsID = useApplicationsStore((state) => state.setApplicationsID);
  const setCurrentCampaignID = useApplicationsStore((state) => state.setCurrentCampaignID);
  const setCurrentJobID = useApplicationsStore((state) => state.setCurrentJobID);

  return useCallback(
    (table: MRT_TableInstance<ApplicationListItem>, applicationID: string) => {
      const applicationsID = table.getRowModel().rows.map((row) => row.original.id);
      setCurrentApplicationID(applicationID);
      setApplicationsID(applicationsID);
      setCurrentCampaignID(campaignID ?? null);
      setCurrentJobID(jobID ?? null);

      routerPush({ pathname: `${pathname}/view`, query: { id: campaignID ?? jobID } }, undefined, {
        shallow: false,
      }).catch(Logger.error);
    },
    [
      campaignID,
      jobID,
      pathname,
      routerPush,
      setApplicationsID,
      setCurrentApplicationID,
      setCurrentCampaignID,
      setCurrentJobID,
    ],
  );
};
