import { useMemo } from "react";

import { MRT_ColumnDef } from "material-react-table";
import { useTranslation } from "next-i18next";

import { loadTranslations } from "@lib";
import { ListJobItem } from "@typings";

import { ApplicationsCell, NameCell, OverviewCell } from "./cells";

export const useJobsColumns = () => {
  const { t } = useTranslation(["jobs-list"]);
  loadTranslations("jobs-list");

  return useMemo<MRT_ColumnDef<ListJobItem, any>[]>(
    () => [
      {
        id: "name",
        accessorFn: (row) => row.title,
        Cell: ({ row }) => <NameCell row={row} />,
        header: t("columns.name.header"),
        minSize: 130,
        enableGlobalFilter: true,
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        id: "city",
        accessorFn: (row) => row.city,
        header: t("columns.city.header"),
        enableGlobalFilter: true,
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        id: "applications",
        Cell: ({ row }) => <ApplicationsCell row={row} />,
        header: t("columns.applications.header"),
        enableColumnFilter: false,
        enableGlobalFilter: false,
        enableSorting: false,
      },
      {
        id: "recap",
        Cell: ({ row }) => <OverviewCell row={row} />,
        header: t("columns.overview.header"),
        enableColumnFilter: false,
        enableGlobalFilter: false,
        enableSorting: false,
      },
    ],
    [t],
  );
};
