import { AppProps } from "next/app";
import Head from "next/head";

import { useEffect } from "react";

import moment from "moment";
import "moment/locale/en-au";
import "moment/locale/fr";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { appWithTranslation } from "next-i18next";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider, PickersLocaleText } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { enUS, frFR } from "@mui/x-date-pickers/locales";
import { LicenseInfo } from "@mui/x-license-pro";

import { DashboardLayout, ErrorBoundary, WithNavigationContext } from "@components";
import { AppProvider } from "@contexts";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { Inter } from "@next/font/google";
import { HydrationBoundary, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import theme from "@theme";
import { NextApplicationPage } from "@typings";
import { AuthProtect, Rum, createEmotionCache } from "@utils";

import { WithOnboarding } from "../components/onboarding";
import "./globals.css";

// Set the MUI license. [It's designed to be public. In fact, it's expected for the license key to be exposed
// in a JavaScript bundle. We just ask our licensed users not to publicize their license keys.]
LicenseInfo.setLicenseKey(
  "4da2c8bcd019c240b5f6dc32322bd22cTz00NjI2NCxFPTE2ODc3MDQxODg0MDQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
);

const matomoSrc = process.env.NEXT_PUBLIC_MATOMO_SRC || "";

const cache = createEmotionCache();

const inter = Inter({ subsets: ["latin"] });

interface PageProps {
  _nextI18Next?: {
    initialLocale?: string;
  };
  dehydratedState: unknown;
  session?: Session | null;
  title?: string;
}

interface TalentAppProps extends AppProps<PageProps> {
  Component: NextApplicationPage<PageProps>;
  emotionCache?: EmotionCache;
}

const localeAdapterForDates: Record<string, string> = {
  // use en-AU for dates because it has the right mix between AM/PM and DD/MM/YYYYm which is standard in EMEA.
  // we currently have no customer in US, but we went with the en-US format for the lang. Eventually, we'll need to fix
  "en-US": "en-au",
  fr: "fr",
};

const localeTextForDates: Record<string, PickersLocaleText<moment.Moment>> = {
  "en-US": enUS.components.MuiLocalizationProvider.defaultProps.localeText as PickersLocaleText<moment.Moment>,
  fr: frFR.components.MuiLocalizationProvider.defaultProps.localeText as PickersLocaleText<moment.Moment>,
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (_error, query) => {
      if (query.meta?.errorMessage) {
        toast.error(query.meta.errorMessage as string);
      }
    },
  }),
  defaultOptions: {
    // We can override all this options on any query
    queries: {
      // disable refetch when browser tab is focused again
      refetchOnWindowFocus: false,
      // staleTime is the time in milliseconds for which the data will be considered
      // fresh and will be fetched from the cache instead of refetching with a call
      staleTime: 60 * 1000,
      // gcTime is the time after which the inactive queries will be garbage collected
      gcTime: 5 * 60 * 1000,

      retry: 0,
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function TalentApp({ Component, emotionCache = cache, pageProps }: TalentAppProps) {
  const locale = pageProps?._nextI18Next?.initialLocale || "fr";

  const localeForDate = localeAdapterForDates[locale];
  moment.locale(localeForDate);

  // See: https://seiza.matomo.cloud/index.php?module=CoreHome&action=index&idSite=1&period=day&date=yesterday#?period=day&date=yesterday&activeTab=reactjs
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== "production") return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _mtm: any = ((window as any)._mtm = (window as any)._mtm || []);
    _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
    const d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = matomoSrc;
    s.parentNode?.insertBefore(g, s);
  }, []);

  useEffect(() => {
    // add font variable to body
    // can't add it in _document because it's server rendered
    document.body.classList.add(inter.className);
  }, []);

  return (
    <main className={inter.className} style={{ height: "100%" }}>
      <Head>
        <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
      </Head>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <SessionProvider session={pageProps.session}>
            <Rum />
            <CacheProvider value={emotionCache}>
              <ThemeProvider theme={theme}>
                <LocalizationProvider
                  adapterLocale={localeForDate}
                  dateAdapter={AdapterMoment}
                  localeText={localeTextForDates[locale]}
                >
                  <CssBaseline />
                  {Component.auth ? (
                    <AuthProtect>
                      <AppProvider>
                        <WithOnboarding>
                          <DashboardLayout title={pageProps.title || ""}>
                            <ErrorBoundary>
                              <WithNavigationContext>
                                <Component {...pageProps} />
                              </WithNavigationContext>
                            </ErrorBoundary>
                          </DashboardLayout>
                        </WithOnboarding>
                      </AppProvider>
                    </AuthProtect>
                  ) : (
                    <Component {...pageProps} />
                  )}
                  <ToastContainer
                    position={toast.POSITION.BOTTOM_RIGHT}
                    autoClose={3000}
                    hideProgressBar
                    closeOnClick
                    pauseOnHover
                  />
                </LocalizationProvider>
              </ThemeProvider>
            </CacheProvider>
          </SessionProvider>
        </HydrationBoundary>
      </QueryClientProvider>
    </main>
  );
}

export default appWithTranslation(TalentApp);
