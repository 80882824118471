import { FC, ReactNode } from "react";

import { useTranslation } from "next-i18next";

import { Chip, Stack } from "@mui/material";

import { loadTranslations } from "@lib";

export interface ActiveFilterValue {
  /**
   * The value of the current filter.
   */
  value: string;
  /**
   * Alternative value to display in the UI. Defaults to the value.
   */
  displayValue?: ReactNode;
  /**
   * An optional count of elements that match this value. If provided, it will be displayed next to the value.
   */
  count?: number;
}

export interface ActiveFilterCategory {
  title: string;
  id: string;
  values: ActiveFilterValue[];
}

export interface ActiveFiltersProps {
  categories: ActiveFilterCategory[];
  onClearFilter: (category: string, value: string) => void;
  onClearCategory: (category: string) => void;
  onClearAll: () => void;
  groupingThreshold?: number;
}

const RenderCategory: FC<
  Pick<ActiveFiltersProps, "onClearFilter" | "onClearCategory" | "groupingThreshold"> & {
    category: ActiveFilterCategory;
  }
> = ({ category, onClearFilter, onClearCategory, groupingThreshold = 8 }) => {
  const { t } = useTranslation(["table"]);
  loadTranslations("table");

  if (category.values.length > groupingThreshold) {
    return (
      <Chip
        label={`${category.title}: ${t("activeFilters.overflow", { count: category.values.length })}`}
        color="deepPurple"
        onDelete={() => onClearCategory(category.id)}
        size="large"
      />
    );
  }

  return (
    <>
      {category.values.map((value) => (
        <Chip
          key={value.value}
          label={
            <>
              {category.title}:&nbsp;{value.displayValue ?? value.value}&nbsp;{value.count ? `(${value.count})` : ""}
            </>
          }
          color="deepPurple"
          onDelete={() => onClearFilter(category.id, value.value)}
          size="large"
        />
      ))}
    </>
  );
};

export const ActiveFilters: FC<ActiveFiltersProps> = ({
  categories,
  onClearFilter,
  onClearCategory,
  onClearAll,
  groupingThreshold,
}) => {
  const { t } = useTranslation(["table"]);
  loadTranslations("table");

  if (!categories.length) return null;

  return (
    <Stack flexDirection="row" gap={(theme) => theme.spacings[8]} flexWrap="wrap">
      {categories.map((category) => (
        <RenderCategory
          key={category.title}
          category={category}
          onClearFilter={onClearFilter}
          onClearCategory={onClearCategory}
          groupingThreshold={groupingThreshold}
        />
      ))}
      <Chip label={t("activeFilters.reset")} color="deepPurple" onDelete={onClearAll} size="large" />
    </Stack>
  );
};
