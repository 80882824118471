import { useRouter } from "next/router";

import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Logger } from "@utils";

type Primitives = string | number | boolean | Record<any, any> | any[];

const EMPTY_VALUES = ["", "null", "undefined", "[]", "{}"];

const doParse = <Initial>(raw: string, parser: (raw: string) => Initial, fallback: Initial): Initial => {
  try {
    if (raw == null || raw === "") return fallback;
    return parser(raw) ?? fallback;
  } catch (e) {
    Logger.error(e);
    return fallback;
  }
};

const extractStringURLParam = (param: string | string[] | undefined, defaultValue: string = ""): string => {
  if (param != null && Array.isArray(param)) return param.length ? param[0] : defaultValue;
  return param ?? defaultValue;
};

interface SyncedState<Value extends Primitives> {
  ok: boolean;
  parse: (raw: string) => Value | undefined;
  initial?: Value;
  cachedKey: string;
}

const useURLSyncedState = <Value extends Primitives>({
  ok,
  parse,
  initial,
  cachedKey,
}: SyncedState<Value>): [Value | undefined, Dispatch<SetStateAction<Value | undefined>>] => {
  const { push, pathname, query } = useRouter();

  const cachedValue = useMemo(() => extractStringURLParam(query[cachedKey], ""), [query, cachedKey]);
  const value = useMemo(() => doParse(cachedValue, parse, initial), [cachedValue, initial, parse]);

  const setValue = useCallback<Dispatch<SetStateAction<Value | undefined>>>(
    (newValue) => {
      if (!ok) return;

      const { [cachedKey]: prevValue, ...newQuery } = query;
      const parsedValue = JSON.stringify(typeof newValue === "function" ? newValue(value) : newValue);

      if (parsedValue === prevValue) return;

      if (EMPTY_VALUES.includes(parsedValue)) {
        push({ pathname, query: newQuery }, undefined, { shallow: true }).catch(Logger.error);
        return;
      }

      push({ pathname, query: { ...newQuery, [cachedKey]: parsedValue } }, undefined, { shallow: true }).catch(
        Logger.error,
      );
    },
    [cachedKey, ok, pathname, push, query, value],
  );

  return [value, setValue];
};

const useLocalStorageSyncedState = <Value extends Primitives>({
  ok,
  parse,
  initial,
  cachedKey,
}: SyncedState<Value>): [Value | undefined, Dispatch<SetStateAction<Value | undefined>>] => {
  const [value, setValue] = useState<Value | undefined>(() => {
    const item = window.localStorage.getItem(cachedKey);
    return doParse(item ?? "", parse, initial);
  });

  const setParsedValue = useCallback<Dispatch<SetStateAction<Value | undefined>>>(
    (newValue) => {
      if (!ok) return;

      const parsedValue = JSON.stringify(typeof newValue === "function" ? newValue(value) : newValue);
      if (EMPTY_VALUES.includes(parsedValue)) {
        window.localStorage.removeItem(cachedKey);
        setValue(undefined);
        return;
      }

      window.localStorage.setItem(cachedKey, parsedValue);
      setValue(newValue);
    },
    [cachedKey, ok, value],
  );

  return [value, setParsedValue];
};

const useDerivedReference = <Value>(value: Value): MutableRefObject<Value> => {
  const valueRef = useRef(value);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return valueRef;
};

export interface SyncMergeProps<Value extends Primitives> {
  localStorage: Value | undefined;
  url: Value | undefined;
  inMemory: Value | undefined;
}

export interface SyncedStateProps<Value extends Primitives> {
  cacheKey: string;
  url?: boolean;
  localStorage?: boolean;
  initialValue?: Value;
  parse: (raw: string) => Value | undefined;
  onBeforeSave?: (value: Value | undefined, destination: "url" | "localStorage" | "") => Value | undefined;
  onMerge: (props: SyncMergeProps<Value>, isFirst: boolean) => Value | undefined;
}

export const useSyncedState = <Value extends Primitives>({
  cacheKey,
  url = false,
  localStorage = false,
  initialValue,
  parse,
  onBeforeSave,
  onMerge,
}: SyncedStateProps<Value>): [Value | undefined, Dispatch<SetStateAction<Value | undefined>>] => {
  const [urlValue, setURLValue] = useURLSyncedState({ ok: url, parse, initial: initialValue, cachedKey: cacheKey });
  const [localStorageValue, setLocalStorageValue] = useLocalStorageSyncedState({
    ok: localStorage,
    parse,
    initial: initialValue,
    cachedKey: cacheKey,
  });
  const [inMemoryValue, setInMemoryValue] = useState<Value | undefined>(initialValue);
  const isFirstRender = useRef(true);

  const value = useMemo(
    // URL has top priority as it is what is directly visible to user, and what it expects to share.
    // InMemory value is volatile, thus it has the lowest priority.
    // Since all values are synced after first render, this order of priority mainly affects the initial rendering
    // (where values are read from cache and not synced to it.
    () => {
      const isFirst = isFirstRender.current;
      isFirstRender.current = false;

      return onMerge(
        {
          localStorage: localStorageValue,
          url: urlValue,
          inMemory: inMemoryValue,
        },
        isFirst,
      );
    },
    [inMemoryValue, localStorageValue, onMerge, urlValue],
  );

  // Little hack, so that we can prevent our updater from being dependent on the memoized value, thus keeping its
  // in-memory reference stable.
  const valueRef = useDerivedReference(value);

  const setValue = useCallback<Dispatch<SetStateAction<Value | undefined>>>(
    (newValue) => {
      const parsedValue: Value | undefined = typeof newValue === "function" ? newValue(valueRef.current) : newValue;

      setInMemoryValue(parsedValue);

      if (onBeforeSave) {
        setLocalStorageValue(onBeforeSave(parsedValue, "localStorage"));
        setURLValue(onBeforeSave(parsedValue, "url"));
      } else {
        setLocalStorageValue(parsedValue);
        setURLValue(parsedValue);
      }
    },
    [onBeforeSave, setLocalStorageValue, setURLValue, valueRef],
  );

  // Ensure values are sync on initial render.
  useEffect(() => {
    if (isFirstRender.current) {
      setValue((prevValue) => prevValue);
    }
  }, [setValue]);

  return [value, setValue];
};
