import { FC, useMemo } from "react";

import { MRT_Row } from "material-react-table";
import { useTranslation } from "next-i18next";

import { Chip, Stack, Typography } from "@mui/material";

import { loadTranslations } from "@lib";
import { ListJobItem } from "@typings";

import { useFetchJobApplicationsList } from "@hooks/queries";

export const ApplicationsCell: FC<{ row: MRT_Row<ListJobItem> }> = ({ row }) => {
  const { t } = useTranslation(["jobs-list"]);
  loadTranslations("jobs-list");

  const { applications } = useFetchJobApplicationsList(row.original.title);
  const newApplications = useMemo(
    () => applications.filter((application) => application.status === "new").length,
    [applications],
  );

  return (
    <Stack
      flexDirection="row"
      flexWrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
      gap={(theme) => theme.spacings[8]}
    >
      <Typography fontSize="0.8rem" fontWeight={700} lineHeight="150%" color={(theme) => theme.palette.color.BASE[600]}>
        {applications.length}
      </Typography>
      {newApplications > 0 ? (
        <Chip
          label={t("columns.applications.new", { count: newApplications })}
          sx={(theme) => ({
            borderRadius: "16px",
            backgroundColor: theme.palette.color.acidGreen,
            color: theme.palette.color.BASE[600],
            fontWeight: 700,
            fontSize: "0.8rem",
            lineHeight: "150%",
          })}
        />
      ) : null}
    </Stack>
  );
};
