import { Dispatch, FC, SetStateAction, useCallback, useState } from "react";

import { useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { Button, ThemeProvider, useTheme } from "@mui/material";

import { Modal } from "@work4Labs/design-system";

import { ApplicationApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { useQuery } from "@tanstack/react-query";
import { ApplicationStatusUpdateParams, Status } from "@typings";
import { Logger } from "@utils";

import { useApplicationStatusUpdater } from "../../application/application-name-status";
import { SelectApplicationStatus } from "../../common";

type ApplicationStatusUpdateModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  campaignID: string;
  applicationID: string;
  onSuccess: () => void;
};

export const ApplicationStatusUpdateModal: FC<ApplicationStatusUpdateModalProps> = ({
  open,
  setOpen,
  campaignID,
  applicationID,
  onSuccess,
}) => {
  const { t } = useTranslation(["update-status-modal"]);
  loadTranslations("update-status-modal");

  const theme = useTheme();

  // we have the `applications` array but the values contains in those are partial, thus this query.
  const { data: application } = useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS, applicationID],
    queryFn: () => ApplicationApi.get(applicationID),
    // refetch the application every minute to ensure that the messages history is
    // up-to-date
    refetchInterval: 60 * 1000,
  });

  const [newStatus, setNewStatus] = useState<Status | null>(null);
  const [params, setParams] = useState<ApplicationStatusUpdateParams | null>(null);

  const updateStatus = useApplicationStatusUpdater(applicationID);

  const onStatusChange = useCallback((newStatus: Status, params: ApplicationStatusUpdateParams) => {
    setNewStatus(newStatus);
    setParams(params);
  }, []);

  const onStatusConfirm = useCallback(
    (newStatus: Status, params: ApplicationStatusUpdateParams) => {
      try {
        updateStatus(newStatus, params).catch((e) => {
          Logger.error(e);
          toast.error(t("error"));
        });
        setOpen(false);
        onSuccess();
        toast.success(t("success"));
      } catch (e) {
        toast.error(t("error"));
      }
    },
    [onSuccess, setOpen, t, updateStatus],
  );

  const onConfirm = useCallback(() => {
    if (!newStatus || !params) {
      return;
    }
    onStatusConfirm(newStatus, params);
  }, [newStatus, onStatusConfirm, params]);

  const customActions = (onConfirm: (() => void) | undefined, onClose: (() => void) | undefined) => {
    const buttonStyle = {
      fontSize: "1rem",
      fontWeight: "600",
      textTransform: "none",
      borderRadius: "8px",
    };
    return (
      <>
        <Button
          color="secondary"
          onClick={() => {
            onClose?.();
          }}
          sx={{
            ...buttonStyle,
            color: theme.palette.primary.main,
            backgroundColor: "white",
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={() => {
            onConfirm?.();
          }}
          sx={{
            ...buttonStyle,
            color: "white",
            backgroundColor: theme.palette.color.deepPurple,
            "&:hover": {
              backgroundColor: theme.palette.color.deepPurple,
            },
          }}
        >
          {t("submit")}
        </Button>
      </>
    );
  };

  return (
    <Modal
      isOpen={open}
      aria-labelledby="update-status-modal-title"
      aria-describedby="update-status-modal-description"
      modalTitle={t("modal_title")}
      title={t("title")}
      // onConfirm, update the status in the back
      onConfirm={onConfirm}
      onClose={() => setOpen(false)}
      customActions={customActions}
    >
      <ThemeProvider theme={theme}>
        <SelectApplicationStatus
          applications={application ? [application] : []}
          campaignId={campaignID}
          // onChange, save the selected statuses in a state
          onChange={(newStatus, params) => {
            if (newStatus) {
              onStatusChange(newStatus, params);
            }
          }}
          onStatusConfirm={onStatusConfirm}
          sendSMS={true}
        />
      </ThemeProvider>
    </Modal>
  );
};
