import { ChangeEvent, FC } from "react";

import { useTranslation } from "next-i18next";

import { TextField } from "@mui/material";

import { loadTranslations } from "@lib";

export interface GlobalFilterProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const GlobalFilter: FC<GlobalFilterProps> = ({ value, onChange }) => {
  const { t } = useTranslation(["table"]);
  loadTranslations("table");

  return (
    <TextField
      id="global_filter"
      placeholder={t("globalFilter.search")}
      value={value ?? ""}
      onChange={onChange}
      sx={(theme) => ({
        backgroundColor: theme.palette.color.BASE[100],
      })}
      inputProps={{
        style: {
          padding: "0.5rem 1rem",
        },
      }}
    />
  );
};
