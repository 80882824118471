import { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "next-i18next";

import { JobsApi } from "@api";
import { ManagedMaterialReactTable, Spinner, useManagedMaterialReactTable } from "@components";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { useQuery } from "@tanstack/react-query";
import { ListJobItem } from "@typings";

import { useUserGroup } from "@hooks";

import { RowActions } from "./cells";
import { INITIAL_COLUMNS_VISIBILITY } from "./constants";
import { useOnRowClick } from "./hooks";
import { useJobsColumns } from "./jobs-columns";

export const JobsList = () => {
  const { t } = useTranslation(["jobs-list"]);
  loadTranslations("jobs-list");

  const organization = useUserGroup();

  const columns = useJobsColumns();

  const [filterableColumns] = useState<string[]>(["name", "city"]);

  const onRowClick = useOnRowClick();

  const translateColumn = useCallback((columnID: string) => t(`columns.${columnID}.shortName`), [t]);

  const jobsQuery = useQuery({
    queryKey: [QUERY_KEYS.JOBS_LIST],
    queryFn: () => JobsApi.list({}),
  });

  const jobs = useMemo(() => jobsQuery.data?.jobs ?? ([] as ListJobItem[]), [jobsQuery.data]);

  const table = useManagedMaterialReactTable({
    seizaFilters: {
      cacheKey: "jobs_list",
      initialColumnsVisibility: INITIAL_COLUMNS_VISIBILITY,
    },
    columns,
    data: jobs,
    state: { isLoading: jobsQuery.isLoading },
    initialState: {
      pagination: { pageSize: 50, pageIndex: 0 },
      columnPinning: { right: ["mrt-row-actions"] },
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableRowSelection: false,
    enableColumnFilters: false,
    enableGlobalFilter: true,
    enableHiding: false,
    enableRowActions: true,
    enableTopToolbar: false,
    enableFacetedValues: true,
    muiTableContainerProps: { id: "jobs_list" },
    muiTableBodyRowProps: ({ row }) => ({
      id: `job_item_${row.original.id}`,
      className: "job_item",
    }),
    muiTableBodyCellProps: ({ cell, row }) => ({
      onClick: () => {
        if (cell.id !== "mrt-row-select" && !cell.id.includes("mrt-row-actions")) onRowClick(row.original.id);
      },
    }),
    renderRowActions: ({ table, row }) => (
      <RowActions row={row} disabled={table.getSelectedRowModel().rows.length > 0} />
    ),
  });

  const { setColumnOrder } = table;

  // Ensure that the columns are in the correct order.
  useEffect(() => {
    setColumnOrder(["mrt-row-select", ...columns.map((column) => column.id!), "mrt-row-actions"]);
  }, [columns, setColumnOrder]);

  if (!organization) {
    return <Spinner style={{ position: "relative" }} />;
  }

  return (
    <ManagedMaterialReactTable
      table={table}
      title={t("title")}
      filterableColumns={filterableColumns}
      translateColumn={translateColumn}
      globalFilter
    />
  );
};
