import { FC } from "react";

import { MRT_Row } from "material-react-table";
import moment from "moment";
import { useTranslation } from "next-i18next";

import { loadTranslations } from "@lib";
import { ApplicationListItem } from "@typings";
import { elaspedTimeFromNow } from "@utils";

export const LastInteractionDateCell: FC<{ row: MRT_Row<ApplicationListItem> }> = ({ row }) => {
  const { t } = useTranslation(["dates"]);
  loadTranslations("dates");

  return <>{elaspedTimeFromNow(t, moment(row.original.last_interaction_date))}</>;
};
