import {
  Application,
  ApplicationShare,
  ApplicationStatusReasonSource,
  ApplicationStatusReasons,
  ApplicationsReport,
  CommentKind,
  DocumentsLogs,
  Question,
  Status,
} from "@typings";

import { applicationsAPI } from "./api";
import { downloadFileFromResponse } from "./utils";

interface ListApplicationsQueryParams {
  organization_names?: Array<string>;
  campaign_id?: string;
  job_title?: string;
}
const list = async (params: ListApplicationsQueryParams): Promise<Array<Application>> => {
  const { data } = await applicationsAPI.get<Array<Application>>("", { params });
  return data;
};

const get = async (id: string): Promise<Application> => {
  const { data } = await applicationsAPI.get<Application>(`/${id}`);
  return data;
};

const deleteApplication = async (id: string): Promise<void> => {
  await applicationsAPI.delete(`/${id}`);
  return;
};

const listStatuses = async (): Promise<Array<Status>> => {
  const { data } = await applicationsAPI.get("/statuses");
  return data;
};

export interface UpdateApplicationStatusParams {
  application_id: string;
  body: {
    id: string; // status id
    is_bulk_action: boolean;
    send_sms: boolean;
  };
}
export interface UpdateApplicationStatusResponse {
  id: string;
}
const updateApplicationStatus = async (
  params: UpdateApplicationStatusParams,
): Promise<UpdateApplicationStatusResponse> => {
  const { data } = await applicationsAPI.post(`/${params.application_id}/status`, params.body);
  return data;
};

export type ApplicationStatusReason = {
  source: ApplicationStatusReasonSource | "";
  reason: ApplicationStatusReasons[keyof ApplicationStatusReasons];
  comment: string;
};

export type CreateApplicationStatusReasonParams = {
  application_id: string;
  status_id: string;
  body: ApplicationStatusReason;
};

const createApplicationStatusReason = async ({
  status_id,
  application_id,
  body,
}: CreateApplicationStatusReasonParams): Promise<void> => {
  const { data } = await applicationsAPI.post(`/${application_id}/status/${status_id}/reason`, body);
  return data;
};

export interface GetApplicationStatusReasonParams {
  application_id: string;
  status_id: string;
}
const getApplicationStatusReason = async (
  params: GetApplicationStatusReasonParams,
): Promise<ApplicationStatusReason> => {
  const { data } = await applicationsAPI.get(`/${params.application_id}/status/${params.status_id}/reason`);
  return data;
};

interface CreateApplicationCommentParams {
  application_id: string;
  body: {
    content: string; // the content of the comment
    is_bulk_action: boolean;
    kind: CommentKind;
  };
}
const createComment = async (params: CreateApplicationCommentParams): Promise<void> => {
  await applicationsAPI.post(`/${params.application_id}/comments`, params.body);
};

interface GetReportingQueryParams {
  organization_names?: Array<string>;
  campaign_id?: string;
}
const getReporting = async (params: GetReportingQueryParams): Promise<ApplicationsReport> => {
  const { data } = await applicationsAPI.get(`/reporting`, { params });
  return data;
};

interface ListQuestionsQueryParams {
  organization_names?: Array<string>;
  campaign_id?: string;
}
const listQuestions = async (params: ListQuestionsQueryParams): Promise<Array<Question>> => {
  const { data } = await applicationsAPI.get("/questions", { params });
  return data;
};

interface GetResumeQueryParams {
  application_id: string;
  lang: string;
}
const getResume = async (params: GetResumeQueryParams): Promise<void> => {
  const res = await applicationsAPI.get(`/${params.application_id}/resume?lang=${params.lang}`, {
    headers: {
      Accept: ["application/pdf", "image/jpeg", "image/png", "image/webp", "application/msword"],
      "Content-Type": ["application/pdf", "image/jpeg", "image/png", "image/webp", "application/msword"],
    },
    responseType: "blob",
  });

  downloadFileFromResponse(res, { defaultFilename: "resume.pdf" });
};

export interface ShareApplicationParams {
  application_id: string;
  body: {
    emails: Array<string>;
  };
}

const shareApplication = async (params: ShareApplicationParams): Promise<void> => {
  await applicationsAPI.post(`/${params.application_id}/share`, params.body);
};

const listApplicationShares = async (applicationID: string): Promise<Array<ApplicationShare>> => {
  const { data } = await applicationsAPI.get(`/${applicationID}/share`);
  return data;
};

interface RequestDocumentsParams {
  application_id: string;
  body: {
    document_type: string;
  };
}

const requestDocuments = async (params: RequestDocumentsParams): Promise<void> => {
  await applicationsAPI.post(`/${params.application_id}/documents/request`, params.body);
};

const listDocuments = async (applicationID: string): Promise<Array<string>> => {
  const { data } = await applicationsAPI.get(`/${applicationID}/documents/`);
  return data;
};

const listDocumentsLogs = async (applicationID: string): Promise<Array<DocumentsLogs>> => {
  const { data } = await applicationsAPI.get(`/${applicationID}/documents/logs`);
  return data;
};

interface CreateDocumentsLogsParams {
  application_id: string;
  body: {
    documents_type: string;
    log_type: string;
  };
}

const createDocumentsLogs = async (params: CreateDocumentsLogsParams): Promise<Array<string>> => {
  const { data } = await applicationsAPI.post(`/${params.application_id}/documents/logs`, params.body);
  return data;
};

interface UploadDocumentsParams {
  application_id: string;
  document_type: string;
  body: FormData;
}

const uploadDocuments = async (params: UploadDocumentsParams): Promise<void> => {
  await applicationsAPI.postForm(`/${params.application_id}/documents/${params.document_type}`, params.body);
};

export const ApplicationApi = {
  get,
  list,
  deleteApplication,
  listStatuses,
  updateApplicationStatus,
  createComment,
  getReporting,
  listQuestions,
  getResume,
  createApplicationStatusReason,
  getApplicationStatusReason,
  shareApplication,
  requestDocuments,
  listDocuments,
  listDocumentsLogs,
  createDocumentsLogs,
  uploadDocuments,
  listApplicationShares,
};
