import { useRouter } from "next/router";

import { FC, MouseEvent, useCallback, useState } from "react";

import { MRT_Row } from "material-react-table";
import { useTranslation } from "next-i18next";

import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import { Button, Menu, MenuItem, Typography } from "@mui/material";

import { loadTranslations } from "@lib";
import { ListJobItem } from "@typings";
import { Logger } from "@utils";

type Props = {
  row: MRT_Row<ListJobItem>;
  disabled?: boolean;
};

export const RowActions: FC<Props> = ({ row, disabled }) => {
  const { t } = useTranslation(["jobs-list"]);
  loadTranslations("jobs-list");

  const { pathname, push: routerPush } = useRouter();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOpenApplication = useCallback(() => {
    routerPush({ pathname: `${pathname}/${row.original.id}/applications` }, undefined, {
      shallow: false,
    }).catch(Logger.error);
    handleClose();
  }, [handleClose, pathname, routerPush, row.original.id]);

  return (
    <>
      <div>
        <Button
          id="actions-button"
          aria-controls={open ? "actions-button" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon />}
          variant="contained"
          color="deepPurple"
          disabled={disabled}
          sx={{
            border: 0,
          }}
        >
          {t("rowActions.label")}
        </Button>
        <Menu
          id="actions-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "actions-button",
          }}
        >
          <MenuItem onClick={handleOpenApplication}>
            <Typography variant="body" color={(theme) => theme.palette.text.mainInfo}>
              {t("rowActions.viewApplications")}
            </Typography>
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};
