import { useCallback, useMemo } from "react";

import { MRT_Column, MRT_TableInstance } from "material-react-table";
import { useTranslation } from "next-i18next";

import { AdvancedFilterCategory, AdvancedFilterValue } from "@components";
import { loadTranslations } from "@lib";
import { ApplicationListItem } from "@typings";

import { EXCLUDED_COLUMNS } from "../constants";

export const useAdvancedFilters = (table: MRT_TableInstance<ApplicationListItem>) => {
  const { t } = useTranslation(["applications-advanced-filters", "leads"]);
  loadTranslations("applications-advanced-filters");
  loadTranslations("leads");

  const { getAllColumns, setColumnVisibility } = table;

  const allColumns = getAllColumns();

  const baseFiltersColumns = useMemo(
    () => allColumns.filter((column) => !EXCLUDED_COLUMNS.includes(column.id) && !column.id.startsWith("question_")),
    [allColumns],
  );

  const questionsColumns = useMemo(
    () => allColumns.filter((column) => column.id.startsWith("question_")),
    [allColumns],
  );

  const baseFiltersColumnsValues = useMemo(
    () => baseFiltersColumns.map((column) => ({ value: column.id, displayValue: t(column.id, { ns: "leads" }) })),
    [baseFiltersColumns, t],
  );

  const questionsColumnsValues = useMemo(
    () => questionsColumns.map((column) => ({ value: column.id, displayValue: column.columnDef.header })),
    [questionsColumns],
  );

  const selectedBaseFiltersColumns = useMemo(
    () =>
      baseFiltersColumns
        .filter((column) => column.getIsVisible())
        .map((column) => ({ value: column.id, displayValue: t(column.id, { ns: "leads" }) })),
    [baseFiltersColumns, t],
  );

  const selectedQuestionFiltersColumns = useMemo(
    () =>
      questionsColumns
        .filter((column) => column.getIsVisible())
        .map((column) => ({ value: column.id, displayValue: column.columnDef.header })),
    [questionsColumns],
  );

  const filtersSetter = useCallback(
    (src: MRT_Column<ApplicationListItem>[], selected: AdvancedFilterValue[]) => {
      setColumnVisibility((prevValue) => {
        src.forEach((column) => {
          prevValue[column.id] = selected.some((value) => value.value === column.id);
        });

        return { ...prevValue };
      });
    },
    [setColumnVisibility],
  );

  const setBaseFiltersColumns = useCallback(
    (selected: AdvancedFilterValue[]) => filtersSetter(baseFiltersColumns, selected),
    [baseFiltersColumns, filtersSetter],
  );

  const setQuestionsColumns = useCallback(
    (selected: AdvancedFilterValue[]) => filtersSetter(questionsColumns, selected),
    [questionsColumns, filtersSetter],
  );

  const advancedFilters = useMemo<AdvancedFilterCategory[]>(
    () => [
      {
        columns: 2,
        defaultExpanded: true,
        title: t("base_filters", { ns: "applications-advanced-filters" }),
        values: baseFiltersColumnsValues,
        selected: selectedBaseFiltersColumns,
        onApply: setBaseFiltersColumns,
      },
      {
        title: t("qualifications", { ns: "applications-advanced-filters" }),
        filterFn: (value, filter) => (value.displayValue as string).toLowerCase().includes(filter.toLowerCase()),
        values: questionsColumnsValues,
        selected: selectedQuestionFiltersColumns,
        onApply: setQuestionsColumns,
      },
    ],
    [
      baseFiltersColumnsValues,
      questionsColumnsValues,
      selectedBaseFiltersColumns,
      selectedQuestionFiltersColumns,
      setBaseFiltersColumns,
      setQuestionsColumns,
      t,
    ],
  );

  return { advancedFilters };
};
