import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { MRT_RowData, MaterialReactTable } from "material-react-table";
import { useTranslation } from "next-i18next";

import { Stack, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

import { PageTitle, columnFilterStateToManaged, useManagedMaterialReactTable } from "@components";
import { loadTranslations } from "@lib";

import { ActiveFilterCategory, ActiveFilters } from "./active-filters";
import { AdvancedFilterCategory, AdvancedFilters } from "./advanced-filters";
import { Categories, Category } from "./categories";
import { MultiSelectFilter, MultiSelectFilterValue } from "./filters";
import { GlobalFilter } from "./global-filter";
import { GroupAction, GroupActions } from "./group-actions";

const ManagedMRTTitle: FC<{ title?: ReactNode }> = ({ title }) => {
  if (!title) return null;

  return (
    <Stack flexDirection="column" gap={(theme) => theme.spacings[12]}>
      <PageTitle>{title}</PageTitle>
    </Stack>
  );
};

const ManagedMRTCategories = <TData extends MRT_RowData>({
  categories,
  onCategoryChange,
}: Pick<ManagedMaterialReactTableProps<TData>, "categories" | "onCategoryChange">) => {
  if (categories == null || onCategoryChange == null) return null;

  return (
    <Categories>
      {categories.map((category) => (
        <Category
          key={category.value}
          id={category.id}
          icon={category.icon}
          count={category.count}
          selected={category.selected}
          onClick={() => onCategoryChange(category.value)}
        >
          {category.displayValue ?? category.value}
        </Category>
      ))}
    </Categories>
  );
};

export interface ManagedMaterialReactTableCategory {
  icon?: OverridableComponent<SvgIconTypeMap>;
  value: string;
  displayValue?: ReactNode;
  count?: number;
  id?: string;
  selected: boolean;
}

const RenderMRTColumnFilter = <TData extends MRT_RowData>({
  table,
  translateColumn,
  translateValue,
  columnID,
  values,
}: {
  table: ReturnType<typeof useManagedMaterialReactTable<TData>>;
  translateValue?: (value: string) => ReactNode;
  translateColumn: (columnID: string) => ReactNode;
  columnID: string;
  values?: MultiSelectFilterValue[] | ((page: number, filter: string) => Promise<MultiSelectFilterValue[]>);
}) => {
  const { t } = useTranslation(["table"]);
  loadTranslations("table");

  const { getColumn } = table;

  const column = getColumn(columnID);
  const optionsValues = column.getFacetedUniqueValues();

  const options = useMemo(() => {
    const result: Array<MultiSelectFilterValue> = [];
    optionsValues.forEach((count, value) => {
      result.push({ count, value, displayValue: translateValue?.(value as string) });
    });
    return result.sort((a, b) => (a.value > b.value ? 1 : -1));
  }, [optionsValues, translateValue]);

  const selected = useMemo(() => {
    const filterValue: string[] = (column.getFilterValue() as string[]) || [];
    return options.filter((option) => filterValue.includes(option.value));
  }, [column, options]);

  return (
    <MultiSelectFilter
      title={t("wrapper.filterBy", { column: translateColumn(columnID) })}
      values={values ?? options}
      selected={selected}
      id={columnID}
      onApply={(selected) => column.setFilterValue(selected.map((option) => option.value))}
    />
  );
};

const ManagedMRTActionsRow = <TData extends MRT_RowData>({
  table,
  groupedActions,
  filterableColumns,
  translateColumn,
  translateColumnValues,
  advancedFilters,
  globalFilter,
  maxAdvancedFilters,
  managedFilters,
}: Pick<
  ManagedMaterialReactTableProps<TData>,
  | "table"
  | "groupedActions"
  | "filterableColumns"
  | "translateColumn"
  | "translateColumnValues"
  | "advancedFilters"
  | "globalFilter"
  | "maxAdvancedFilters"
  | "managedFilters"
>) => {
  const { getSelectedRowModel, getState, setGlobalFilter, getColumn } = table;

  // Directly using the table method is slow for some reason. Having this local state that then propagates to the
  // table yields quicker results in the UI (search time is the same, but typing is lagged when using the table method).
  const [localGlobalFilter, setLocalGlobalFilter] = useState<string>((getState().globalFilter as string) ?? "");

  useEffect(() => {
    setGlobalFilter(localGlobalFilter);
  }, [localGlobalFilter, setGlobalFilter]);

  const selectedRows = getSelectedRowModel().rows.map((row) => row.original);

  const canRenderColumnFilters = filterableColumns != null && translateColumn != null;

  if (groupedActions == null && !canRenderColumnFilters && advancedFilters == null && !globalFilter) {
    return null;
  }

  return (
    <Stack id="filter_list" flexDirection="row" flexWrap="wrap" gap={(theme) => theme.spacings[12]}>
      {groupedActions != null ? <GroupActions selected={selectedRows} actions={groupedActions} /> : null}

      {canRenderColumnFilters
        ? filterableColumns
            .filter((column) => getColumn(column).getIsVisible())
            .map((columnID) => (
              <RenderMRTColumnFilter
                key={columnID}
                table={table}
                translateColumn={translateColumn}
                translateValue={translateColumnValues?.get(columnID)}
                columnID={columnID}
                values={managedFilters?.[columnID]}
              />
            ))
        : null}

      {advancedFilters ? <AdvancedFilters categories={advancedFilters} maxFilters={maxAdvancedFilters ?? 7} /> : null}

      {globalFilter ? (
        <GlobalFilter
          value={localGlobalFilter}
          onChange={(e) => {
            setLocalGlobalFilter(e.target.value);
          }}
        />
      ) : null}
    </Stack>
  );
};

const ManagedMRTActiveFilters = <TData extends MRT_RowData>({
  table,
  translateColumn,
  showActiveFilters,
  filterableColumns,
  activeFiltersGroupingThreshold,
}: Pick<
  ManagedMaterialReactTableProps<TData>,
  "table" | "translateColumn" | "showActiveFilters" | "activeFiltersGroupingThreshold" | "filterableColumns"
>) => {
  const { getColumn, setColumnFilters, getState } = table;

  const rawColumnFilters = getState().columnFilters;
  const columnFilters = useMemo(
    () =>
      columnFilterStateToManaged(rawColumnFilters.filter((column) => filterableColumns?.includes(column.id) ?? false)),
    [filterableColumns, rawColumnFilters],
  );

  const isActiveFilter = useMemo(
    () => columnFilters?.some((columnFilter) => columnFilter.value.length > 0) ?? false,
    [columnFilters],
  );

  const onClearAll = useCallback(() => {
    setColumnFilters([]);
  }, [setColumnFilters]);

  const onClearCategory = useCallback(
    (category: string) => {
      setColumnFilters((prevValue) => prevValue?.filter((filter) => filter.id !== category));
    },
    [setColumnFilters],
  );

  const onClearFilter = useCallback(
    (category: string, value: string) => {
      setColumnFilters((prevValue) =>
        prevValue?.map((filter) =>
          filter.id === category
            ? { ...filter, value: Array.isArray(filter.value) ? filter.value.filter((v) => v !== value) : filter.value }
            : filter,
        ),
      );
    },
    [setColumnFilters],
  );

  const categories = useMemo<ActiveFilterCategory[]>(
    () =>
      columnFilters.map((columnFilter) => ({
        title: translateColumn?.(columnFilter.id) ?? columnFilter.id,
        id: columnFilter.id,
        values: columnFilter.value.map((value) => ({
          value,
          count: getColumn(columnFilter.id).getFacetedUniqueValues().get(value),
        })),
      })),
    [columnFilters, getColumn, translateColumn],
  );

  if (!showActiveFilters || !isActiveFilter || !filterableColumns) return null;

  return (
    <ActiveFilters
      onClearAll={onClearAll}
      onClearCategory={onClearCategory}
      onClearFilter={onClearFilter}
      groupingThreshold={activeFiltersGroupingThreshold ?? 8}
      categories={categories}
    />
  );
};

export type ValuesTranslator = Map<string, (value: string) => ReactNode>;

export interface ManagedMaterialReactTableProps<TData extends MRT_RowData> {
  table: ReturnType<typeof useManagedMaterialReactTable<TData>>;
  title?: ReactNode;

  categories?: ManagedMaterialReactTableCategory[];
  onCategoryChange?: (category: string) => void;

  groupedActions?: GroupAction<TData>[];

  filterableColumns?: string[];
  advancedFilters?: AdvancedFilterCategory[];
  translateColumn?: (columnID: string) => string;
  translateColumnValues?: ValuesTranslator;
  maxAdvancedFilters?: number;
  managedFilters?: Record<
    string,
    MultiSelectFilterValue[] | ((page: number, filter: string) => Promise<MultiSelectFilterValue[]>)
  >;

  globalFilter?: boolean;
  showActiveFilters?: boolean;
  activeFiltersGroupingThreshold?: number;
}

export const ManagedMaterialReactTable = <TData extends MRT_RowData>({
  table,
  title,
  categories,
  onCategoryChange,
  groupedActions,
  filterableColumns,
  translateColumn,
  translateColumnValues,
  advancedFilters,
  globalFilter,
  maxAdvancedFilters,
  showActiveFilters,
  activeFiltersGroupingThreshold,
  managedFilters,
}: ManagedMaterialReactTableProps<TData>) => (
  <Stack flexDirection="column" gap={(theme) => theme.spacings[12]}>
    <ManagedMRTTitle title={title} />
    <ManagedMRTCategories categories={categories} onCategoryChange={onCategoryChange} />
    <ManagedMRTActionsRow
      table={table}
      groupedActions={groupedActions}
      filterableColumns={filterableColumns}
      translateColumn={translateColumn}
      translateColumnValues={translateColumnValues}
      advancedFilters={advancedFilters}
      globalFilter={globalFilter}
      maxAdvancedFilters={maxAdvancedFilters}
      managedFilters={managedFilters}
    />
    <ManagedMRTActiveFilters
      table={table}
      translateColumn={translateColumn}
      filterableColumns={filterableColumns}
      showActiveFilters={showActiveFilters ?? false}
      activeFiltersGroupingThreshold={activeFiltersGroupingThreshold}
    />
    <MaterialReactTable table={table} />
  </Stack>
);
