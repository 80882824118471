import { ScoringAPI } from "@api";
import { QUERY_KEYS } from "@constants";
import { useQuery } from "@tanstack/react-query";

export const useScoringEnabledQuery = (campaignID: string | undefined) =>
  useQuery({
    queryKey: [QUERY_KEYS.CAMPAIGN_HAS_SCORING, campaignID],
    queryFn: () => ScoringAPI.hasEngine(campaignID ?? ""),
    enabled: !!campaignID,
  });
