import { URLSearchParams } from "next/dist/compiled/@edge-runtime/primitives/url";

import { Job, ListJobCity, ListJobItem, ListJobTitle } from "@typings";

import { jobsAPI } from "./api";
import { SortingOrder } from "./utils";

export interface ListJobsParams {
  sort_by_city?: SortingOrder;
  sort_by_title?: SortingOrder;
  search?: string;
  title?: string[];
  city?: string[];
  limit?: number;
  offset?: number;
}

export interface ListJobsKeysParams {
  search?: string;
  search_key?: string;
  title?: string[];
  city?: string[];
  limit?: number;
  offset?: number;
}

export interface ListJobsResponse {
  jobs: ListJobItem[];
  total: number;
}

const parseListParams = (src: Record<string, any>) => {
  const searchParams = new URLSearchParams();

  Object.entries(src).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value
        .filter((v) => v != null)
        .forEach((v) => searchParams.append(key, typeof v === "string" ? v : JSON.stringify(v)));
    } else if (value != null) {
      searchParams.append(key, typeof value === "string" ? value : JSON.stringify(value));
    }
  });

  return searchParams.toString();
};

const list = async (params: ListJobsParams): Promise<ListJobsResponse> => {
  const { data, headers } = await jobsAPI.get("/list", { params, paramsSerializer: parseListParams });
  return {
    jobs: data,
    total: parseInt((headers["x-total-count"] as string) ?? "0", 10),
  };
};

const listCities = async (params: ListJobsKeysParams): Promise<ListJobCity[]> => {
  const { data } = await jobsAPI.get("/list/cities", { params, paramsSerializer: parseListParams });
  return data;
};

const listTitles = async (params: ListJobsKeysParams): Promise<ListJobTitle[]> => {
  const { data } = await jobsAPI.get("/list/titles", { params, paramsSerializer: parseListParams });
  return data;
};

const get = async (id: string): Promise<Job> => {
  const { data } = await jobsAPI.get(`/${id}`);
  return data;
};

export interface SearchJobsParams {
  organization_name: string;
  job_id?: Array<string>;
  title?: Array<string>;
  city?: Array<string>;
  postal_code?: Array<string>;
  category?: Array<string>;
  api_ref?: Array<string>;
  contract?: Array<string>;
  campaign_id?: Array<string>;
}

export const search = async (params: SearchJobsParams): Promise<Array<Job>> => {
  const { data } = await jobsAPI.get("/", {
    params,
  });
  return data;
};

export const JobsApi = {
  get,
  search,
  list,
  listCities,
  listTitles,
};
